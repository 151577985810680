import React, { useState, useEffect } from 'react';
import Card from "../Card/Card.js";
import utils from "../../utils.js";
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import LoadingStateConstants from "../Loading/LoadingStateConstants.js";
import GameStateConstants from "../Game/GameStateConstants.js";
import CardContentTypeGenerator from "../Card/CardContentTypeGenerator.js";
import "./Board.scss";
import "../../responsiveGrid.scss";

/***************************************************************/
const loadingAnimation = (input) => {
    setTimeout(() => {
        input.gameModel.app.setPanel("loading", LoadingStateConstants.FADE);

        setTimeout(() => {
            input.gameModel.app.setPanel("loading", LoadingStateConstants.HIDE);
            input.gameModel.startGame();
        }, 3010);
    }, 1500);
};
/***************************************************************/
const Board = (input) => {
    const [cards, setCards] = useState([]);
    const [appListenerId, setAppListenerId] = useState(null);
    const [gameListenerId, setGameListenerId] = useState(null);

    const cardContentTypeGenerator = new CardContentTypeGenerator(input.contentType);
    /***************************************************************/
    useEffect(() => {
        if (input.contentType === "color" || input.contentType === "alphanum") {
            setCards(makeCards());
            loadingAnimation(input);
        }
        else if (input.contentType === "pictures") {
            if (input.contentInfo.length >= 3) {
                
                if (!input.gameModel.app.extraIsSet()) {
                    const params = {
                        Bucket: input.gameModel.app.aws.config.s3Bucket,
                        Prefix: input.contentInfo + '/',
                    };

                    let s3 = input.gameModel.app.aws.getS3();
                    console.log("We just made the request, and now we are waiting...");
                    s3.listObjectsV2(params, (err, data) => {
                        if (err) {
                            console.log(err, err.stack);
                        } else {
                            console.log("FINALLY");
                            if (data.Contents.length > 0) {
                                // filter out the folder file
                                const filtered = data.Contents.filter(d => d.Size > 0);
                                console.log("finally");

                                const e = {
                                    "contentId": input.contentInfo,
                                    "picturesInventory": filtered
                                };
                                cardContentTypeGenerator.setInventory(filtered);
                                cardContentTypeGenerator.setExtra({ "contentId": input.contentInfo });

                                setCards(makeCards(e));
                                loadingAnimation(input);
                                input.gameModel.app.setExtra(e);
                            }
                        }
                    });
                }
            }
        }

        /*******Set listener for GameModel**************************************/
        const gameTempId = input.gameModel.registerListener((propsChanged) => {
            // also listen to changes on the cards
            if (propsChanged === "gameState" && input.gameModel.gameState === GameStateConstants.NOT_STARTED) {
                updateCards();
            }
        });
        setGameListenerId(gameTempId);
        /***********************************************************************/
        /******Set listener for AppModel****************************************/
        const tempId = input.gameModel.app.registerListener((propsChanged) => {
        });
        setAppListenerId(tempId);
        /************************************************************************/
        input.gameModel.app.settingsDialog.setTotalInventorySize(cardContentTypeGenerator.getInventorySize());


        /*****Clean up the listeners once the component unmounts*****************/
        return function cleanup() {
            input.gameModel.unregisterListener(gameListenerId);
            input.gameModel.app.unregisterListener(appListenerId);
            setAppListenerId(null);
            setGameListenerId(null);
        }
        /*************************************************************************/
    }, []);
    /***************************************************************/
    const updateCards = () => {
        if (input.contentType === "color" || input.contentType === "alphanum") {
            setCards(makeCards());
            loadingAnimation(input);
        }
        else if (input.contentType === "pictures") {
            const extra = input.gameModel.app.extra;
            setCards(makeCards(extra));
            loadingAnimation(input);
        }
    };

    /***************************************************************/
    const makeCard = (c) => {
        return (<Card
            cardId={c.id}
            gameModel={input.gameModel}
            contentType={input.contentType}
            contentInfo={input.contentInfo}
            content={c} />);
    };
    /***************************************************************/
    const makeCards = (extra = null) => {
        let content = [];
        const isInit = input.gameModel.app.settingsDialog.initialized;
        const nc = isInit ? input.gameModel.app.settingsDialog.numCards : input.numCards; 
        const duplicates = isInit ? input.gameModel.app.settingsDialog.allowDuplicates : input.duplicates;

        if (!input.gameModel.cardsMade()) {
            const n = nc / 2;
            let j = 0;
            
            if (!duplicates)
                cardContentTypeGenerator.createTempInventory();
            
            for (let i = 0; i < n; i++) {
                const data = cardContentTypeGenerator.generate(i, j, duplicates, extra);

                content.push(data.content);
                content.push(data.dup);
                j = data.id + 1; 
            }

            input.gameModel.setCards(content, input.contentType);
        }
        else {
            content = input.gameModel.getCards();
        }

       /* const listItems = utils.shuffle(content.map((c, i) => <Grid xs={4} key={i}>{makeCard(c)}</Grid>));
        return (<Grid
            style={{
                width: "100%",
                boxSizing: "border-box"
            }}
            container
            spacing={1}>{listItems}</Grid>);*/

         const listItems = utils.shuffle(content.map((c, i) => <div className="grid-item" key={i}>{makeCard(c)}</div>));
         return (<div
             style={{
                 width: "100%",
                 boxSizing: "border-box"
             }}
             className="grid-container"
             >{listItems}</div>);
    }
    /***************************************************************/
    return (
        <Box
            className="board"
            sx={{
                flexGrow: 1,
                width: "100%",
                boxSizing: "border-box"
            }}>
            {cards}
        </Box>
    );
}

export default Board;
/***************************************************************/