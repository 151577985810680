import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import "./UserDescription.scss";

const UserDescription = (input) => {
    const [modelListenerId, setModelListenerId] = useState(null);
    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
        });
        setModelListenerId(tempId);

        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);
    
    const capitalizeName = (n) => {
        const firstLetter = n.charAt(0).toUpperCase();
        return firstLetter + n.substring(1);
    }

    const createInstagramLink = () => {
        return "https://www.instagram.com/" + input.instagram;
    }

    const determineDisplayInstagram = () => {
        if (input.instagram !== "")
            return (
                <a href={createInstagramLink()}>
                    <FontAwesomeIcon
                        className="instagram-icon"
                        icon={faInstagram} />
                    {input.instagram}
                </a>
            );
        return null;
    }


    return (
        <div className="userdescription-container">
            <h3>{capitalizeName(input.name)}</h3>
            <p className="instagram-link">{determineDisplayInstagram()}</p>
            <p className="description">{input.description}</p>
        </div>
    );
}

export default UserDescription;
/***************************************************************/
