import React, { useState, useRef, useEffect } from 'react';
import FollowingModal from '../FollowingModal/FollowingModal.js';
import "./NumFollowingDisplay.scss";
import inlineStyles from "./inlineStyles.js";
import helperFunctions from "./helperFunctions.js";

const NumFollowingDisplay = (input) => {

    const [modelListenerId, setModelListenerId] = useState(null);
    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
        });
        setModelListenerId(tempId);

        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);
    
    const determineNumFollowing = () => {
        return input.numFollowing === null ? 2 : input.numFollowing;
    };

    const handleClick = () => {
        input.model.setPanel("followingModal", true);
    }

    return (
        <div onClick={handleClick} className="numfollowingdisplay-container">
            <p>{determineNumFollowing()}</p>
            <p>following</p>
            <FollowingModal
                profilePicture={input.profilePicture}
                model={input.model}
                following={input.following}
            />
        </div>
    );
}

export default NumFollowingDisplay;
/***************************************************************/
