import React, { useState, useRef, useEffect } from 'react';
import "./FollowButton.scss";
import inlineStyles from "./inlineStyles.js";
import helperFunctions from "./helperFunctions.js";

const FollowButton = (input) => {
    const [isLoggedIn, setIsLoggedIn] = useState(input.model.auth.isLoggedIn);
    const [modelListenerId, setModelListenerId] = useState(null);

    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
            if (propsChanged === "auth.isLoggedIn")
                setIsLoggedIn(input.model.auth.isLoggedIn);
        });
        setModelListenerId(tempId);

        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);
    
    const determinedFollowDisplay = () => {
        if (isLoggedIn) {
            const followingSet = new Set(input.model.auth.userProfile.data.following);
            if (followingSet.has(input.username)) {
                return (<button
                    disabled={input.username === "olivia" || input.username === "monica"}
                    className="follow-button"
                >following</button>);
            }
            else if (input.username !== input.model.auth.userProfile.data.username) {
                return (<button className="follow-button">follow</button>);
            }
        }
        return null;
    };

    return (
        <div className="followbutton-container">
            {determinedFollowDisplay()}
        </div>
    );
}

export default FollowButton;
/***************************************************************/
