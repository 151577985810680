import React, { useState, useRef, useEffect } from 'react';
import "./UpdateGame.scss";
import GameInfo from "../GameInfo/GameInfo.js";
import GameImageList from "../GameImageList/GameImageList.js";
import { getGamesByFolder } from "../../services/firebase/firebase.js";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import inlineStyles from "./inlineStyles.js";
import helperFunctions from "./helperFunctions.js";

const UpdateGame = (input) => {

    const [modelListenerId, setModelListenerId] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(input.model.auth.isLoggedIn);
    const [selectedTab, setSelectedTab] = useState("game-info");


    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
            if (propsChanged === "auth.isLoggedIn")
                setIsLoggedIn(input.model.auth.isLoggedIn);
        });
        setModelListenerId(tempId);



        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);
    

    const determineTab = () => {
        if (selectedTab === "game-info") {
            return (
                <div className="game-info">
                    <GameInfo
                        game={input.game}
                        model={input.model}
                    />
                </div>
            );
        }
        else if (selectedTab === "images") {
            return (
                <div className="images-wrapper input-field">
                    <div className="input-label">Images</div>
                    <GameImageList
                        game={input.game}
                        model={input.model}
                    />
                </div>
            );
        }

        return null; 
    }
    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <div className="updategame-container">
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className="accordion-header">Game Information</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <GameInfo
                            game={input.game}
                            model={input.model}
                        />
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className="accordion-header">Images</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <GameImageList
                            game={input.game}
                            model={input.model}
                        />
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                >
                    <Typography className="accordion-header">Add Images</Typography>
                </AccordionSummary>
            </Accordion>
        </div>
    );
}

export default UpdateGame;
/***************************************************************/
