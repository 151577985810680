import React, { useState, useRef, useEffect } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import "./AllowDuplicatesController.scss";

const AllowDuplicatesController = (input) => {

    const [modelListenerId, setModelListenerId] = useState(null);
    const initValue = input.isMaker ? input.model.maker.settings.allowDuplicates :
        input.model.settingsDialog.allowDuplicates;
    const [value, setValue] = useState(initValue);
    
    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
            if (propsChanged === "settingsDialog.allowDuplicates")
                setValue(input.model.settingsDialog.allowDuplicates);
            else if (propsChanged === "settingsDialog.tempSettings.allowDuplicates")
                setValue(input.model.settingsDialog.tempSettings.allowDuplicates);
            else if (propsChanged === "maker.settings.allowDuplicates")
                setValue(input.model.maker.settings.allowDuplicates);
        });
        setModelListenerId(tempId);

        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);

    const handleChange = (event) => {
        if (!input.isMaker)
            input.model.settingsDialog.setTempAllowDuplicates(event.target.value);
        else
            input.model.maker.setSetting("allowDuplicates", event.target.value);
    };
    

    return (
        <div className="allowduplicatescontroller-container">
            <FormControl className="form-control">
                <FormLabel id="radio-buttons-group-label" className="form-label">Allow Duplicates?</FormLabel>
                <RadioGroup
                    aria-labelledby="radio-buttons-group-label"
                    defaultValue="true"
                    value={value}
                    onChange={handleChange}
                    name="radio-buttons-group"
                >
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                </RadioGroup>
            </FormControl>
        </div>
    );
}

export default AllowDuplicatesController;
/***************************************************************/
