import React, { useState, useRef, useEffect } from 'react';
import { useParams } from "react-router-dom";
import UserOverview from './UserOverview/UserOverview.js';
import UserDescription from "./UserDescription/UserDescription.js";
import GameList from "../GameList/GameList.js";
import PageTemplate from '../PageTemplate/PageTemplate.js';
import PageBody from "../PageTemplate/PageBody/PageBody.js";
import ErrorUI from "../ErrorUI/ErrorUI.js";
import { getUser, getGamesByUser, getAssociatedUsers } from "../../services/firebase/firebase.js";
import "./UserProfile.scss";


const UserProfile = (input) => {
    const { user } = useParams();

    const [isLoggedIn, setIsLoggedIn] = useState(input.model.auth.isLoggedIn);
    const [profile, setProfile] = useState({
        followers: [], 
        following: [], 
        instagram: null, 
        displayName: null, 
        description: null,
        username: user, 
        email: "",
        profilePicture: "",
        numFollowers: 0, 
        numFollowing: 0
    });
    const [games, setGames] = useState({
        gameList: null,
        numGames: 0
    });
    const [isValidUser, setIsValidUser] = useState(true);
    const [modelListenerId, setModelListenerId] = useState(null);


    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
            if (propsChanged === "auth.isLoggedIn")
                setIsLoggedIn(input.model.auth.isLoggedIn);
            if (propsChanged === "auth.userProfile.data") {
                if(isLoggedIn) getUserInfo().then((uProfile) => {});
            }
        });
        setModelListenerId(tempId);
        getGameList();
        getUserInfo();

        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);

    const getUserInfo = async () => {
        let u = await getUser(user);
        setIsValidUser(u.length > 0);
        if (u.length > 0) {
            let uFinal = u[0];
            let assocUsers = await getAssociatedUsers(user);

            const uProfile = {
                followers: assocUsers.followers,
                following: assocUsers.following,
                description: uFinal.description,
                displayName: uFinal.displayName,
                username: user, 
                email: uFinal.email, 
                instagram: uFinal.instagram,
                profilePicture: uFinal.profilePicture,
                numFollowers: assocUsers.followers.length, 
                numFollowing: assocUsers.following.length
            };

            setProfile(uProfile);
            return uProfile; 
        }
        return null; 
    };

    const getGameList = async () => {
        const g = await getGamesByUser(user);
        if (g.length > 0) {
            const gamesTemp = {
                numGames: g.length,
                gameList: g
            };
            setGames({ ...gamesTemp });
        }
    };
    
    const gameListSet = () => {
        if (games.gameList !== null)
            return <GameList
                model={input.model}
                owner={user}
                gameList={games.gameList} />
        return null; 
    }

    const determineDisplay = () => {
        if (isValidUser) {
            return (
                   <>
                    <UserOverview
                        model={input.model}
                        numGames={games.numGames}
                        numFollowers={profile.numFollowers}
                        numFollowing={profile.numFollowing}
                        followers={profile.followers}
                        following={profile.following}
                        instagram={profile.instagram}
                        userProfile={profile}
                        profilePicture={profile.profilePicture}
                        username={profile.username} />
                    <UserDescription
                        model={input.model}
                        userProfile={profile}
                        instagram={profile.instagram}
                        name={profile.displayName === null? user : profile.displayName}
                        description={profile.description}
                    />
                    <div className="game-list">
                        {gameListSet()}
                    </div>
                   </>
            );
        }

        return (<ErrorUI
            model={input.model}
            msg="Whoops! User does not exist." />);
    }

    return (
        <div className="userprofile-container">
            <PageTemplate
                title="user profile"
                model={input.model}>
                <PageBody model={input.model}>
                    {determineDisplay()}
                </PageBody>
            </PageTemplate>
        </div>
    );
}

export default UserProfile;
/***************************************************************/
