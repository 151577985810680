const inlineStyles = {
    button: {
        width: "100%",
        boxSizing: "border-box",
        border: "5px solid #eff7fc",
        backgroundColor: "rgba(208, 232, 248,0.7)",
        padding: "0.8em !important", 
        borderRadius: "10px", 
        cursor: "pointer",
        boxShadow: "0px 0px 18px 0px rgba(0, 0, 0, 0.15)",
        color: "black !important"
    }
};

export default inlineStyles;

