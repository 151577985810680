import React, { useState, useRef, useEffect } from 'react';
import "./FollowersModal.scss";
import UserListModal from "../UserListModal/UserListModal.js";
import inlineStyles from "./inlineStyles.js";
import helperFunctions from "./helperFunctions.js";

const FollowersModal = (input) => {

    const [modelListenerId, setModelListenerId] = useState(null);
    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
        });
        setModelListenerId(tempId);

        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);
    

    return (
        <UserListModal
                profilePicture={input.profilePicture}
                model={input.model}
                userList={input.followers}
                userListName={"Followers"}
                panelName={"followersModal"}
            />
    );
}

export default FollowersModal;
/***************************************************************/
