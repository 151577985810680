import React, { useState, useRef, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import LoggedInUser from "./LoggedInUser/LoggedInUser.js";
import NotLoggedInUser from "./NotLoggedInUser/NotLoggedInUser.js";
import "./Auth.scss";
import helperFunctions from './helperFunctions.js';

const Auth = (input) => {
    const [modelListenerId, setModelListenerId] = useState(null);
    const [authMode, setAuthMode] = useState("signin");
    const [isLoggedIn, setIsLoggedIn] = useState(input.model.auth.isLoggedIn);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
            if (propsChanged === "panels.authModal") {
                setOpen(input.model.panels.authModal);
            }

            if (propsChanged === "auth.isLoggedIn") {
                setIsLoggedIn(input.model.auth.isLoggedIn);
            }
        });
        setModelListenerId(tempId);

        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);
    
    const handleClose = (event) => {
        input.model.setPanel("authModal", false);
        event.stopPropagation();
    }

    const handleAuthModeChange = (event, newValue) => {
        setAuthMode(newValue);
    };

    const determineLoggedIn = () => {
        if (!isLoggedIn) {
            return (
                <NotLoggedInUser
                    model={input.model}
                    authMode={authMode}
                    handleAuthModeChange={handleAuthModeChange}
                />
            );
        }

        return (
            <LoggedInUser 
                model={input.model}
                handleClose={handleClose}
            />
        );
    }


    return (
        <div className="auth-container">
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="draggable-dialog-title">
                {determineLoggedIn()}
            </Dialog>
        </div>
    );
}

export default Auth;
/***************************************************************/
