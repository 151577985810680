import React, { useState, useRef, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { db } from "../../../services/firebase/firebase.js";
import "./Signup.scss";
import inlineStyles from "./inlineStyles.js";
import helperFunctions from "./helperFunctions.js";

const Signup = (input) => {

    const [modelListenerId, setModelListenerId] = useState(null);
    const [formInput, setFormInput] = useState({
        firstName: "",
        lastName: "",
        email: "",
        username: "",
        password: "",
        repeatPassword: ""
    });
    const [inputErrors, setInputErrors] = useState(null);
    const [buttonClicked, setButtonClicked] = useState(false);

    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
        });
        setModelListenerId(tempId);

        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);
    
    const handleInputChange = (event, field) => {
        if (buttonClicked)
            setButtonClicked(false);
        
        let updatedFormInput = { ...formInput };
        updatedFormInput[field] = event.target.value; 
        setFormInput(updatedFormInput);
    }

    const handleSignUpClicked = () => {
        helperFunctions.manageSignUpErrors(formInput).then((tempErrors) => {
            setButtonClicked(true);
            setInputErrors(tempErrors);
        });
    }

    const determineOutput = () => {
        if (buttonClicked) {
            const hasErrors = inputErrors.totalErrors > 0; 
            if (!hasErrors) 
                return <p className="signup-success">Success! But still in dev mode, not connected to auth service yet.</p>
            
            // make the error list 
            let errorMsgs = [];
            Object.keys(inputErrors).forEach((k) => {
                const err = inputErrors[k];
                if (k === "required")
                    errorMsgs = [...errorMsgs, ...err];
                else if(err !== null && k !== "totalErrors")
                    errorMsgs.push(err);
            });

            return (
                <ul className="signup-fail">
                    {errorMsgs.map((msg,i) => {
                        return (<li key={i} className="error-msg">{msg}</li>)
                    })}
                </ul>
            )
        }
    }

    return (
        <div className="signup-container">
            <div className="signup-header">
                <h2>Sign Up</h2>
                {determineOutput()}                
            </div>
            <div className="firstname-wrapper input-field">
                <div className="input-label">First Name<span className="required">*</span></div>
                <TextField
                    name="firstName"
                    value={formInput.firstName}
                    onChange={(event) => handleInputChange(event, "firstName")}
                    label="first name"
                    variant="outlined"
                    required />
            </div>
            <div className="lastname-wrapper input-field">
                <div className="input-label">Last Name</div>
                <TextField
                    name="lastName"
                    onChange={(event) => handleInputChange(event, "lastName")}
                    value={formInput.lastName}
                    label="last name"
                    variant="outlined" />
            </div>
            <div className="email-wrapper input-field">
                <div className="input-label">Email<span className="required">*</span></div>
                <TextField
                    name="email"
                    onChange={(event) => handleInputChange(event, "email")}
                    value={formInput.email}
                    label="email"
                    variant="outlined"
                    required />
            </div>
            <div className="username-wrapper input-field">
                <div className="input-label">Desired Username<span className="required">*</span></div>
                <div className="input-requirements">
                    <ul>
                        <li>Usernames must contain 2 or more characters.</li>
                        <li>Characters can be letters, numbers, underscores or periods.</li>
                        <li>The first character and the last characters can only be letters or numbers.</li>
                    </ul>
                </div>
                <TextField
                    name="username"
                    onChange={(event) => handleInputChange(event, "username")}
                    value={formInput.username}
                    label="desired username"
                    variant="outlined"
                    required />
            </div>
            <div className="password-wrapper input-field">
                <div className="input-label">Password<span className="required">*</span></div>
                <div className="input-requirements">
                    <ul>
                        <li>Password must contain more than 7 characters.</li>
                        <li>Password must contain at least one number.</li>
                        <li>Password must contain at least one uppercase letter.</li>
                    </ul>
                </div>
                <TextField
                    name="password"
                    onChange={(event) => handleInputChange(event, "password")}
                    value={formInput.password}
                    type="password"
                    label="password"
                    variant="outlined"
                    required />
            </div>
            <div className="password-again-wrapper input-field">
                <div className="input-label">Repeat Password<span className="required">*</span></div>
                <TextField
                    name="repeatPassword"
                    onChange={(event) => handleInputChange(event, "repeatPassword")}
                    value={formInput.repeatPassword}
                    type="password"
                    label="password again"
                    variant="outlined"
                    required />
            </div>
            <button
                onClick={handleSignUpClicked}
                className="button-19" role="button">Sign Up</button>
        </div>
    );
}

export default Signup;
/***************************************************************/
