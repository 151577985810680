import React, { useState, useEffect } from 'react';
import ColorCard from '../ColorCard/ColorCard.js';
import AlphaNumCard from '../AlphaNumCard/AlphaNumCard.js';
import PictureCard from "../PictureCard/PictureCard.js";

/***************************************************************/
const CardFactory = (input) => {
    /***************************************************************/
    const [appListenerId, setAppListenerId] = useState(null);

    /***************************************************************/
    useEffect(() => {
        const tempId = input.gameModel.registerListener((propsChanged) => {
        });
        setAppListenerId(tempId);


        return function cleanup() {
            input.gameModel.unregisterListener(appListenerId);
            setAppListenerId(null);
        };

    }, []);

    /***************************************************************/
    if (input.contentType === "color") {
        return <ColorCard content={input.content.content} />
    }
    else if (input.contentType === "alphanum") {
        return <AlphaNumCard content={input.content.content} />
    }
    else if (input.contentType === "pictures") {
        return <PictureCard content={input.content.content}/>
    }
    return null;
};
/***************************************************************/
export default CardFactory;
/***************************************************************/