import React, { useState, useRef, useEffect } from 'react';

const MAX_DESCRIPTION_CHARS = 350; 
const helperFunctions = {
    numLeft: (n) => {
        const left = MAX_DESCRIPTION_CHARS - parseInt(n, 10); 
        return left;
    },
    manageErrors: async (formInput) => {
        let tempErrors = {
            "descriptionLength": null,
            totalErrors: 0
        };

        if (formInput.description.length > MAX_DESCRIPTION_CHARS) {
            tempErrors["descriptionLength"] = "Description must be less than " + MAX_DESCRIPTION_CHARS + " characters.";
            tempErrors.totalErrors += 1;
        }


        return tempErrors;
    }
};

export {helperFunctions, MAX_DESCRIPTION_CHARS}; 
