import CardContentType from "../CardContentType.js";

class AlphaNumType extends CardContentType {
    constructor() {
        super("alphanum");
    }
    makeDuplicatesAllowed(pairId, id, inventory) {
        const data = inventory[~~(Math.random() * inventory.length)];
        let content = {};
        let dup = {};

        content = {
            "content": data,
            "id": id,
            "pairId": pairId
        };
        id++;
        dup = {
            "content": data,
            "id": id,
            "pairId": pairId
        };   

        return {
            content: content,
            dup: dup,
            id: id
        };
    }
    makeNoDuplicates(pairId, id, inventory, alg = 2) {
        if (alg === 2) {
            if (pairId === 0)
                inventory = this.utils.shuffle(inventory);
        }

        let index = alg === 1 ? (~~(Math.random() * inventory.length)) : pairId; 
        const data = inventory[index];
        let content = {};
        let dup = {};

        content = {
            "content": data,
            "id": id,
            "pairId": pairId
        };
        id++;
        dup = {
            "content": data,
            "id": id,
            "pairId": pairId
        };

        if (alg === 1)
            inventory.splice(index, 1);

        return {
            content: content,
            dup: dup,
            id: id,
            inventory: inventory
        };
    }
    make(gen, pairId, id, allowDuplicates, extra = null) {
        if (allowDuplicates)
            return this.makeDuplicatesAllowed(pairId, id, gen.inventory);
        
        return this.makeNoDuplicates(pairId, id, gen.tempInventory);
    }
};

export default AlphaNumType; 