import React, { useState, useRef, useEffect } from 'react';
import "./UserListModal.scss";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import JustProfilePicture from '../JustProfilePicture/JustProfilePicture.js';
import FollowButton from "../FollowButton/FollowButton.js";
import inlineStyles from "./inlineStyles.js";
import helperFunctions from "./helperFunctions.js";

const UserListModal = (input) => {

    const [modelListenerId, setModelListenerId] = useState(null);
    const [open, setOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(input.model.auth.isLoggedIn);

    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
            const panelName = "panels." + input.panelName;
            if (propsChanged === panelName) 
                setOpen(input.model.panels[input.panelName]);

            if (propsChanged === "auth.isLoggedIn")
                setIsLoggedIn(input.model.auth.isLoggedIn);
        });
        setModelListenerId(tempId);

        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);

    const handleClose = (event) => {
        input.model.setPanel(input.panelName, false);
        event.stopPropagation();
    }
    
    const makeList = () => {
        input.userList.sort();
        return input.userList.map((u, i) => {
            const link = "https://matching.oliviasgames.com/u/" + u.username; 
            return (
                <ListItem className="userlist-item" key={i}>
                        <a className="compound-link" href={"/u/" + u.username}>
                            <JustProfilePicture
                                model={input.model}
                                width="50px"
                                height="50px"
                                username={u.username}
                                profilePicture={u.profilePicture}
                            />
                            <div className="userName">{u.username}</div>
                        </a>
                    <FollowButton
                            model={input.model}
                            username={u.username}
                        />
               </ListItem>
           ) 
        });
    }

    return (
            <Dialog className="userlistmodal" onClose={handleClose} open={open}>
                <DialogTitle>{input.userListName}</DialogTitle>
                <List className="list">
                    {makeList()}
                </List>
            </Dialog>
    );
}

export default UserListModal;
/***************************************************************/
