import UserProfileModel from "../UserProfile/UserProfileModel.js";

class AuthModel {
    constructor(app) {
        this.listeners = [];
        this.app = app; 
        this.userProfile = new UserProfileModel(app); 
        this.credentials = null; 
        this.isLoggedIn = false; 
        this.isInit = false; 
        this.liveSessionKey = this.app.name + ".liveSession";
    }
    
    /***************************************************************/
    init() {
        let liveSessionStored = localStorage.getItem(this.liveSessionKey);
        if (liveSessionStored && !this.isInit) {
            liveSessionStored = JSON.parse(liveSessionStored);
            this.isLoggedIn = true; 
            this.credentials = liveSessionStored.creds; 
            this.userProfile.setUser(liveSessionStored.user);
            this.app.notifyListeners("auth.isLoggedIn");
        }
        this.isInit = true; 
    }
    /***************************************************************/
    updateUser(input) {
        this.userProfile.updateUser(input,this.credentials);
    }
    /***************************************************************/
    setIsLoggedIn(loggedIn, creds = null,user = null) {
        // if user logged in, set the user data
        if (loggedIn && !this.isLoggedIn) {
            this.isLoggedIn = loggedIn; 
            this.credentials = creds; 
            this.userProfile.setUser(user);
            localStorage.setItem(this.app.name + ".liveSession", JSON.stringify({
                user: user,
                creds: creds
            }));
            this.app.notifyListeners("auth.isLoggedIn");

        }
        else if(!loggedIn && this.isLoggedIn){
            this.isLoggedIn = loggedIn; 
            this.userProfile.unsetUser();
            localStorage.removeItem(this.app.name + ".liveSession");
            this.app.notifyListeners("auth.isLoggedIn");

        }
    }
    /***************************************************************/
    unregisterListener(id) {
        if (id >= 0 && id < this.listeners.length) {
            this.listeners[id] = null;
        }
    }
    /***************************************************************/
    registerListener(func) {
        this.listeners.push(func);
        return this.listeners.length - 1;
    }
    /***************************************************************/
    notifyListeners(propsChanged) {
        for (let i = 0; i < this.listeners.length; i++) {
            const listener = this.listeners[i];
            if (listener !== null && listener !== undefined) {
                listener(propsChanged);
            }
        }
    }
}

export default AuthModel; 
