import React, { useState, useEffect } from 'react';
import AppLogo from "../../AppLogo/AppLogo.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faMagnifyingGlass, faPuzzlePiece } from '@fortawesome/free-solid-svg-icons';
import Search from "../../Search/Search.js";
import ProfileIconButton from './ProfileIconButton/ProfileIconButton.js';
import { Link } from 'react-router-dom';
import Auth from "../../Auth/Auth.js";
import "./PageHeader.scss";

const PageHeader = (input) => {

    const [modelListenerId, setModelListenerId] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(input.model.auth.isLoggedIn);
    const [showSearch, setShowSearch] = useState(false);

    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
            if (propsChanged === "auth.isLoggedIn") {
                setIsLoggedIn(input.model.auth.isLoggedIn);
            }
        });
        setModelListenerId(tempId);

        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);

    const determineShowSearch = () => {
        if (showSearch)
            return <Search model={input.model} />
        
        return null; 
    }
    

    return (
        <div className="pageheader-container">
            <div className="mini-header">.</div>
            <div className="main-header">
                <div className="left">
                    <div className="left-inner-wrapper">
                        <div className="button">
                            <FontAwesomeIcon
                                icon={faBars} />
                        </div>
                        <div className="button">
                            <FontAwesomeIcon
                                onClick={(event) => { setShowSearch(!showSearch) }}
                                icon={faMagnifyingGlass} />
                        </div>
                    </div>
                </div>
                <AppLogo model={input.model} />
                <div className="right">
                    <div className="right-inner-wrapper">
                        <ProfileIconButton
                            model={input.model}
                        />
                        <div className='button'>
                            <Link to="/maker">
                                <FontAwesomeIcon icon={faPuzzlePiece} />
                            </Link>
                        </div>
                    </div>
                </div>
                <Auth model={input.model} />
            </div>
            {determineShowSearch()}
        </div>
    );
}

export default PageHeader;
/***************************************************************/