import React, { useState, useRef, useEffect } from 'react';
import JustProfilePicture from '../JustProfilePicture/JustProfilePicture.js';
import FollowButton from "../FollowButton/FollowButton.js";
import "./ProfileImage.scss";
import inlineStyles from "./inlineStyles.js";
import helperFunctions from "./helperFunctions.js";

const ProfileImage = (input) => {

    const [modelListenerId, setModelListenerId] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(input.model.auth.isLoggedIn);

    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
            if (propsChanged === "auth.isLoggedIn")
                setIsLoggedIn(input.model.auth.isLoggedIn);
        });
        setModelListenerId(tempId);

        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);


    return (
        <div className="profileimage-container">
                <JustProfilePicture
                    model={input.model}
                    username={input.username}
                    profilePicture={input.profilePicture}
                    width="80px"
                    height="80px"
                />
            <p>{input.username}</p>
            <FollowButton
                model={input.model}
                username={input.username}
            />
        </div>
    );
}

export default ProfileImage;
/***************************************************************/
