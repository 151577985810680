import React, { useState, useRef, useEffect } from 'react';
import { useParams } from "react-router-dom";
import YouWon from '../YouWon/YouWon.js';
import Loading from "../Loading/Loading.js";
import AppBody from "../AppBody/AppBody.js";
import helperFunctions from './helperFunctions.js';

/***************************************************************/
const GameWrapper = (input) => {
    
    const { numCards, content, pictures, duplicates, theme } = useParams();
    const [appListenerId, setAppListenerId] = useState(null);

    const determineNumCards = helperFunctions.determineNumCards(numCards, input);
    const determineDuplicates = helperFunctions.determineDuplicates(duplicates, input);
    const determineTheme = helperFunctions.determineTheme(theme, input);
    const determineContent = helperFunctions.determineContent(content, input);

    const [settings, setSettings] = useState({
        numCards: determineNumCards, 
        contentType: determineContent, 
        contentInfo: pictures, 
        allowDuplicates: determineDuplicates,
        theme: determineTheme
    });

    const [settingsChangedCount, setSettingsChangedCount] = useState(0);
    const prevSettingsChangedCountRef = useRef();
    useEffect(() => {
        //assign the ref's current value to the count Hook
        prevSettingsChangedCountRef.current = settingsChangedCount;
    }, [settingsChangedCount]); //run this code when the value of count changes



    /***************************************************************/
    useEffect(() => {
        // initialize the settingsDialog
        const tempId = input.appModel.registerListener((propsChanged) => {
            if (propsChanged === "settingsDialog.all") {
                //console.log("heard settings changed");
                setSettings(input.appModel.settingsDialog.getSettings());
                setSettingsChangedCount(settingsChangedCount + 1);
            }
        });
        setAppListenerId(tempId);


        return function cleanup() {
            input.appModel.unregisterListener(appListenerId);
            setAppListenerId(null);
        };

    }, []);
    /***************************************************************/

    return (
        <div className="App">
            <Loading appModel={input.appModel} />
            <AppBody
                appModel={input.appModel}
                contentType={settings.contentType}
                contentInfo={settings.contentInfo}
                duplicates={settings.allowDuplicates}
                numCards={settings.numCards}
                theme={settings.theme}
                settingsChangedCount={settingsChangedCount}
                prevSettingsChangedCount={prevSettingsChangedCountRef.current}
            />
            <YouWon
                contentInfo={pictures}
                appModel={input.appModel}
            />
        </div>
    );
};
/***************************************************************/
export default GameWrapper;
