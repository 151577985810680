import React, { useState, useRef, useEffect, useCallback } from 'react';
import TextField from '@mui/material/TextField';
import "./Search.scss";
import inlineStyles from "./inlineStyles.js";
import helperFunctions from "./helperFunctions.js";

const Search = (input) => {
    const [isLoggedIn, setIsLoggedIn] = useState(input.model.auth.isLoggedIn);
    const [dataSet, setDataSet] = useState(null);
    const [formInput, setFormInput] = useState("");
    const [results, setResults] = useState(null);
    const [modelListenerId, setModelListenerId] = useState(null);
    
    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
            if (propsChanged === "auth.isLoggedIn")
                setIsLoggedIn(input.model.auth.isLoggedIn);
        });
        setModelListenerId(tempId);

        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);
    
    const handleInputChange = (event) => {
        setFormInput(event.target.value);
    }

    const determineDisplayResults = () => {
        if (results !== null) {
            return <div className="search-results"></div>;
        }
        return null;
    }

    return (
        <div className="search-container">
            <TextField
                value={formInput}
                onChange={handleInputChange}
                fullWidth
                type="search"
                name="search"
                label="search"
                variant="outlined" />
            {determineDisplayResults()}
        </div>
    );
}
export default Search;
