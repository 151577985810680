import React, { useState, useEffect } from 'react';
import DialogContent from '@mui/material/DialogContent';
import "./LoggedInUser.scss";

const LoggedInUser = (input) => {
    const [isLoggedIn, setIsLoggedIn] = useState(input.model.auth.isLoggedIn);
    const [modelListenerId, setModelListenerId] = useState(null);

    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
            if (propsChanged === "auth.isLoggedIn")
                setIsLoggedIn(input.model.auth.isLoggedIn);
        });
        setModelListenerId(tempId);

        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);
    


    return (
        <div className="loggedinuser-container">
            <DialogContent sx={{ padding: "0em" }}>
                <h1>Hello, {input.model.auth.userProfile.data.displayName}</h1>
                <p>Click anywhere outside this box to close it!</p>
            </DialogContent>
        </div>
    );
}

export default LoggedInUser;
/***************************************************************/
