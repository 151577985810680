import React, { useState, useRef, useEffect } from 'react';
import { useParams } from "react-router-dom";
import ErrorUI from "../ErrorUI/ErrorUI.js";
import PageTemplate from "../PageTemplate/PageTemplate.js";
import PageBody from "../PageTemplate/PageBody/PageBody.js";
import UpdateGame from "../UpdateGame/UpdateGame.js";
import "./UpdateGamePage.scss";

const UpdateGamePage = (input) => {
    const { user,game } = useParams();

    const [modelListenerId, setModelListenerId] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(input.model.auth.isLoggedIn);

    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
            if (propsChanged === "auth.isLoggedIn")
                setIsLoggedIn(input.model.auth.isLoggedIn);
        });
        setModelListenerId(tempId);

        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);
    
    const determineUI = () => {
        if (isLoggedIn && user === input.model.auth.userProfile.data.username)
            return <UpdateGame
                game={game}
                model={input.model} />


        return (<ErrorUI
            model={input.model}
            msg="Whoops! Cannot edit another user's account." />);
    }

    return (
        <div className="updategamepage-container">
            <PageTemplate
                title="update game"
                model={input.model}>
                <PageBody model={input.model}>
                    <h1>Update game {game}</h1>
                    {determineUI()}
                </PageBody>
            </PageTemplate>
        </div>
    );
}

export default UpdateGamePage;
/***************************************************************/
