/**************************************************************/
class GameMakerModel {
    constructor(app) {
        this.listeners = [];
        this.app = app; 
        this.settings = {
            contentType: "pictures",
            contentInfo: null,
            numCards: 12, 
            allowDuplicates: false,
            theme: "main"
        };

    }
    /***************************************************************/
    setSetting(setting, v) {
        this.settings[setting] = v;
        const k = "maker.settings." + setting; 
        this.app.notifyListeners(k);
    }
    /***************************************************************/
    unregisterListener(id) {
        if (id >= 0 && id < this.listeners.length) {
            this.listeners[id] = null;
        }
    }
    /***************************************************************/  
    registerListener(func) {
        this.listeners.push(func);
        return this.listeners.length - 1;
    }
    /***************************************************************/
    notifyListeners(propsChanged) {
        for (let i = 0; i < this.listeners.length; i++) {
            const listener = this.listeners[i];
            if (listener !== null && listener !== undefined) {
                listener(propsChanged);
            }
        }
    }
}

export default GameMakerModel; 
