import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import NumCardsSlider from '../NumCardsSlider/NumCardsSlider';
import AllowDuplicatesController from '../AllowDuplicatesController/AllowDuplicatesController';
import "./SettingsDialog.scss";
import inlineStyles from './inlineStyles.js';


const SettingsDialog = (input) => {

    const [modelListenerId, setModelListenerId] = useState(null);

    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
        });
        setModelListenerId(tempId);

        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);

    const handleButtonClick = (event) => {
        input.model.updateSettings();
        event.stopPropagation();
    };

    const determineAllowDuplicatesControllerShown = () => {
        const contentType = input.model.settingsDialog.contentType; 
        if (contentType === "alphanum" || contentType === "pictures")
            return <AllowDuplicatesController
                model={input.model} />
        return <div>.</div>;
    }

    return (
        <div>
            <div
            style={{
                width: "100%",
                boxSizing: "border-box"
            }}
            className="settingsdialog-container">
            <NumCardsSlider
                model={input.model}
            />
            {determineAllowDuplicatesControllerShown()}
            <Button
                sx={inlineStyles.button}
                onClick={handleButtonClick}
                variant="text">Apply</Button>
            </div>
        </div>
    );
}

export default SettingsDialog;
/***************************************************************/
