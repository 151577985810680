import React, { useState, useRef, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { getGamesByFolder } from "../../services/firebase/firebase.js";
import Switch from '@mui/material/Switch';
import "./GameInfo.scss";
import inlineStyles from "./inlineStyles.js";
import helperFunctions from "./helperFunctions.js";

const GameInfo = (input) => {

    const [modelListenerId, setModelListenerId] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(input.model.auth.isLoggedIn);
    const [formInput, setFormInput] = useState({
        title: "",
        public: true, 
        description: ""
    });
    const [showResult, setShowResult] = useState(false);
    const [hasErrors, setHasErrors] = useState(false);

    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
            if (propsChanged === "auth.isLoggedIn")
                setIsLoggedIn(input.model.auth.isLoggedIn);
        });
        setModelListenerId(tempId);

        getGamesByFolder(input.game).then((gameList) => {
            if (gameList.length > 0) {
                const tempGame = gameList[0];
                const tempFormInput = {
                    title: tempGame.title, 
                    description: tempGame.description, 
                    public: tempGame.public
                }
                setFormInput({ ...tempFormInput });
            }
        }).catch((err) => console.log(err));

        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);

    const determineDisplayResults = () => {
        if (showResult) {
            if (hasErrors) {
                return (<div className="has-errors">
                    <h3>Whoops, errors found.</h3>
                </div>)
            }
            return (<div className='success'>
                <h3>Success!</h3>
            </div>);
        }

        return null;
    };

    return (
        <div className="gameinfo-container">
            {determineDisplayResults()}
            <div className="title-wrapper input-field">
                <div className="input-label">Title</div>
                <TextField
                    value={formInput.title}
                    name="title"
                    label="title"
                    variant="outlined" />
            </div>
            <div className="public-wrapper input-field">
                <div className="input-label">Public</div>
                <FormGroup>
                    <FormControlLabel control={<Switch checked={formInput.public} />} label="Public" />
                </FormGroup>
            </div>
            <div className="description-wrapper input-field">
                <div className="input-label">Description</div>
                <TextField
                    name="description"
                    label="description"
                    value={formInput.description}
                    multiline={true}
                    rows={3}
                    fullWidth={true}
                    variant="outlined" />
            </div>
        </div>
    );
}

export default GameInfo;
/***************************************************************/
