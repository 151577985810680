import CardModelFactory from "../Card/CardModelFactory.js";
import GameStateConstants from "./GameStateConstants.js";
import LoadingStateConstants from "../Loading/LoadingStateConstants.js";

/***************************************************************/
class GameModel {
    constructor() {
        this.cardsFlipped = [];
        this.listeners = [];
        this.cards = [];
        this.matches = 0;
        this.won = false;
        this.app = null;
        this.gameState = GameStateConstants.NOT_STARTED; // not started
        this.playAgain = false; 
        this.contentType = null;
        this.contentInfo = null; 
        this.cardModelFactory = new CardModelFactory();
        this.numCards = 0; 
        this.cardInventory = {};
    }
    /***************************************************************/
    setApp(app) {
        this.app = app; 
        this.app.setGame(this);
    }
    /***************************************************************/
    startGame() {
        this.gameState = GameStateConstants.RUNNING;
        this.app.timer.start();
        this.notifyListeners("gameState");
    }
    /***************************************************************/
    stopGame() {
        this.gameState = GameStateConstants.ENDED;
        this.notifyListeners("gameState");
    }

    /***************************************************************/
    flipCard(c) {
        const card = this.getCard(c.id);
        if (!card.flipped && this.cardsFlipped.length < 2) {
            this.cardsFlipped.push(card);
            card.flip();

            const propsChanged = "cardInventory[" + c.id + "].flipped";
            this.notifyListeners(propsChanged);

            // Wait a little while and then determine if the pair flipped is a match
            setTimeout(() => {
                if (this.cardsFlipped.length === 2) {
                    let c1 = this.cardsFlipped[0]; // first card flipped
                    let c2 = this.cardsFlipped[1]; // second card flipped

                    this.app.session.incrementTurns();

                    if (c1.isMatch(c2)) {
                        const c1PropsChanged = "cardInventory[" + c1.id + "].matchFound";
                        const c2PropsChanged = "cardInventory[" + c2.id + "].matchFound";

                        this.matches += 2;
                        this.notifyListeners(c1PropsChanged);
                        this.notifyListeners(c2PropsChanged);
                    }
                    else {
                        const c1PropsChanged = "cardInventory[" + c1.id + "].flipped";
                        const c2PropsChanged = "cardInventory[" + c2.id + "].flipped";
                        c2.flip();
                        c1.flip();
                        this.notifyListeners(c1PropsChanged);
                        this.notifyListeners(c2PropsChanged);
                    }

                    // empty the flipped cards 
                    this.cardsFlipped = [];
                    if (this.hasWon()) {
                        this.won = true;
                        this.gameState = GameStateConstants.ENDED; 
                        this.app.timer.stop(); 
                        this.app.session.determineBest(this.app.timer.ticks);
                        const settings = {
                            ...this.app.settingsDialog.getSettings(),
                            best: this.app.session.getBest()
                        };
                       // localStorage.setItem("olivias-matching-game-settings",
                       //     JSON.stringify(settings));
                        this.app.notifyListeners("game.won");
                    }
                }
            }, 2000);
        }
    }
    /***************************************************************/
    setCards(cards, contentType) {        
        if (this.cards.length === 0) {
            this.cards = cards.map((c, i) => {
                this.cardInventory[c.id] = i;
                return this.cardModelFactory.make(contentType, c);
            });
        }
    }
    /***************************************************************/
    doPlayAgain() {
        if (!this.app.timer.hasStopped())
            this.app.timer.stop();
        this.app.timer.reset();

        for (let i = 0; i < this.cards.length; i++){
            // resetting each card
            const c = this.cards[i];
            c.matchFound = false;
            c.flipped = false; 
            const matchFoundProp = "cardInventory[" + c.id + "].matchFound";
            const flippedProp = "cardInventory[" + c.id + "].flipped";
            this.notifyListeners(matchFoundProp);
            this.notifyListeners(flippedProp);
        }
        this.playNewGame();
    }
    /***************************************************************/
    playNewGame() {
        this.cardsFlipped = [];
        this.cards = [];
        this.matches = 0;
        this.won = false;
        this.playAgain = false;
        this.gameState = GameStateConstants.NOT_STARTED;
        this.cardInventory = {};
        this.app.session.resetTime();
        this.app.session.resetTurns();
        this.initiateNewGame();
    }
    /****************************************************************/
    initiateNewGame() {
        this.app.setPanel("loading", LoadingStateConstants.SHOW);
        this.notifyListeners("gameState");
    }
    /***************************************************************/
    resetCurrentGame() {
        if (!this.app.timer.hasStopped())
            this.app.timer.stop();
        this.app.timer.reset();
        for (let i = 0; i < this.cards.length; i++) {
            // resetting each card
            const c = this.cards[i];
            c.matchFound = false;
            c.flipped = false;
            const matchFoundProp = "cardInventory[" + c.id + "].matchFound";
            const flippedProp = "cardInventory[" + c.id + "].flipped";
            this.notifyListeners(matchFoundProp);
            this.notifyListeners(flippedProp);
        }
        
        this.cardsFlipped = [];
        this.matches = 0;
        this.won = false;
        this.playAgain = false;
        this.app.session.resetTime();
        this.app.session.resetTurns();
        this.gameState = GameStateConstants.RUNNING;
        this.app.timer.start();
    }
    /***************************************************************/
    hasWon() {
        this.won = this.matches === this.cards.length; 
        return this.won; 
    }
    /***************************************************************/
    getCard(id) {
        return this.cards[this.cardInventory[id]];
    }
    /***************************************************************/
    getCards() {
        return this.cards;
    }
    /***************************************************************/
    cardsMade() {
        return this.cards.length !== 0;
    }
    /***************************************************************/
    unregisterListener(id) {
        if (id >= 0 && id < this.listeners.length) {
            this.listeners[id] = null;
        }
    }
    /***************************************************************/
    registerListener(func) {
        this.listeners.push(func);
        return this.listeners.length - 1;
    }
    /***************************************************************/
    notifyListeners(propsChanged) {
        for (let i = 0; i < this.listeners.length; i++) {
            const listener = this.listeners[i];
            if (listener !== null && listener !== undefined) {
                listener(propsChanged);
            }
        }
    }
};
/***************************************************************/

export default GameModel;