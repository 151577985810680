import React, { useState, useRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import "./AlphaNumCard.scss";
/***************************************************************/
const AlphaNumCard = (input) => {
    /***************************************************************/
    return (
        <Box
            className="card-content"
            style={{
                backgroundColor: "#CAE4F4"
            }}
        >
            <div className="alphanum">{input.content}</div>
        </Box>

    );
};
/***************************************************************/
export default AlphaNumCard;
/***************************************************************/