import React, { useState, useRef, useEffect } from 'react';
import PageTemplate from "../PageTemplate/PageTemplate.js";
import PageBody from "../PageTemplate/PageBody/PageBody.js";
import Logout from "../Auth/Logout/Logout.js";
import ErrorUI from "../ErrorUI/ErrorUI.js";
import "./LogoutPage.scss";
import inlineStyles from "./inlineStyles.js";
import helperFunctions from "./helperFunctions.js";

const LogoutPage = (input) => {

    const [modelListenerId, setModelListenerId] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(input.model.auth.isLoggedIn);

    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
            if (propsChanged === "auth.isLoggedIn")
                setIsLoggedIn(input.model.auth.isLoggedIn);
        });
        setModelListenerId(tempId);

        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);
    
    const determineUI = () => {
        if (isLoggedIn)
            return <Logout model={input.model} />
        return <ErrorUI model={input.model}
            msg="Cannot logout if not logged in!" />
    }

    return (
        <div className="logoutpage-container">
            <PageTemplate
                title="logout"
                model={input.model}>
                <PageBody model={input.model}>
                    {determineUI()}
                </PageBody>
            </PageTemplate>
        </div>
    );
}

export default LogoutPage;
/***************************************************************/
