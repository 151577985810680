import React, { useState, useRef, useEffect } from 'react';
import { auth, signOut } from "../../../services/firebase/firebase.js";
import "./Logout.scss";

const Logout = (input) => {

    const [modelListenerId, setModelListenerId] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(input.model.auth.isLoggedIn);

    const handleLogout = (e) => {
        signOut(auth).then((value) => {
            input.model.auth.setIsLoggedIn(false);
        }).catch((error) => console.log(error));
        e.stopPropagation();
    };

    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
            if (propsChanged === "auth.isLoggedIn")
                setIsLoggedIn(input.model.auth.isLoggedIn);
        });
        setModelListenerId(tempId);

        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);

    
    const determineUI = () => {
        if (isLoggedIn) {
            return (
                <div className="logout-wrapper">
                    <h1>Logout</h1>
                    <p>See ya next time {input.model.auth.userProfile.data.username}!</p>
                    <button className="button-19" onClick={(handleLogout)}>Logout</button>
                </div> 
            );
        }

        return null;
    }

    return (
        <div className="logout-container">
            {determineUI()}
        </div>
    );
}

export default Logout;
/***************************************************************/
