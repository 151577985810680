import React, { useState, useRef, useEffect } from 'react';
import JustProfilePicture from "../../../UserProfile/JustProfilePicture/JustProfilePicture.js";
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Logout from '@mui/icons-material/Logout';
import "./ProfileIconButton.scss";
import inlineStyles from "./inlineStyles.js";
import helperFunctions from "./helperFunctions.js";

const ProfileIconButton = (input) => {

    const [modelListenerId, setModelListenerId] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(input.model.auth.isLoggedIn);
    const [anchorEl, setAnchorEl] = useState(null);
    const [profilePicture, setProfilePicture] = useState(input.model.auth.userProfile.userIsSet() ?
        input.model.auth.userProfile.data.profilePicture : "");
    const menuIsOpen = Boolean(anchorEl);


    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
            if (propsChanged === "auth.isLoggedIn")
                setIsLoggedIn(input.model.auth.isLoggedIn);
            if (propsChanged === "auth.userProfile.data") {
                if (input.model.auth.isLoggedIn && input.model.auth.userProfile.userIsSet())
                    setProfilePicture(input.model.auth.userProfile.data.profilePicture);
            }
        });
        setModelListenerId(tempId);

        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);
    
    const handleClick = (event) => {
        if (!isLoggedIn) 
            input.model.setPanel("authModal", true);
        else 
            setAnchorEl(event.currentTarget);

        event.stopPropagation();
    };

    const handleClose = (event) => {
        setAnchorEl(null);
        event.stopPropagation();
    };

    const determineLink = () => {
        let link = "/u/"; 
        if (isLoggedIn)
            link += input.model.auth.userProfile.data.username;
        return link; 
    }
    return (
        <div className="profileiconbutton-container">
            <JustProfilePicture
                onClick={handleClick}
                model={input.model}
                height="40px"
                width="40px"
                username={isLoggedIn ? input.model.auth.userProfile.data.username : "guest"}
                profilePicture={isLoggedIn ? profilePicture : ""}
            />
            <Menu
                anchorEl={anchorEl}
                open={menuIsOpen}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem className="pib-menu-item">
                    <a href={determineLink()} ><Avatar /> Profile</a>
                </MenuItem>
                <MenuItem className="pib-menu-item">
                    <a href={determineLink() + "/edit"} ><Avatar /> My Account</a>
                </MenuItem>
                <MenuItem className="pib-menu-item">
                    <a href={determineLink() + "/edit"} ><Avatar /> Reset Password</a>
                </MenuItem>
                <Divider />
                <MenuItem className="pib-menu-item">
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    <a href="/logout">Logout</a>
                </MenuItem>
            </Menu>
        </div>
    );
}

export default ProfileIconButton;
/***************************************************************/
