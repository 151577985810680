import React, { useState, useRef, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import CardHeader from '@mui/material/CardHeader';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CreateIcon from '@mui/icons-material/Create';
import "./GameModifyMenu.scss";
import inlineStyles from "./inlineStyles.js";
import helperFunctions from "./helperFunctions.js";

const GameModifyMenu = (input) => {

    const [modelListenerId, setModelListenerId] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(input.model.auth.isLoggedIn);
    const [anchorEl, setAnchorEl] = useState(null);
    const menuIsOpen = Boolean(anchorEl);


    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
            if (propsChanged === "auth.isLoggedIn")
                setIsLoggedIn(input.model.auth.isLoggedIn);
        });
        setModelListenerId(tempId);

        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);

    const handleClickMenu = (e) => {
        setAnchorEl(e.currentTarget);
        e.stopPropagation();
    }

    const handleCloseMenu = (e) => {
        setAnchorEl(null);
        e.stopPropagation();

    };
    

    return (
        <div className="gamemodifymenu-container">
            <CardHeader
                className='user-logged-in-card-header'
                action={
                    <IconButton
                        onClick={handleClickMenu}
                        aria-label="settings">
                        <MoreVertIcon />
                    </IconButton>
                }
            />
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={menuIsOpen}
                onClose={handleCloseMenu}
                onClick={handleCloseMenu}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem
                    sx={{
                        paddingLeft: "0.7em",
                        paddingRight: "1em"
                    }}
                >
                    <a href={"/u/" + input.owner + "/game/" + input.game.folder} className="gmm-menu-item">
                    <IconButton
                        sx={{
                            backgroundColor: "rgba(0,0,0,0.05)",
                            marginRight: "10px"
                        }}
                    >
                        <CreateIcon/>
                    </IconButton>
                        Edit
                    </a>
                </MenuItem>
            </Menu>
        </div>
    );
}

export default GameModifyMenu;
/***************************************************************/
