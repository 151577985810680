import React, { useState, useRef, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import {auth, getUserByEmail, signInWithEmailAndPassword} from "../../../services/firebase/firebase.js";
import "./Signin.scss";

const Signin = (input) => {

    const [modelListenerId, setModelListenerId] = useState(null);
    const [formInput, setFormInput] = useState({
        email: "", 
        password: ""
    });
    const [successful, setSuccessful] = useState(true);

    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
        });
        setModelListenerId(tempId);
        
        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);
    
    const handleInputChange = (event) => {
        if (!successful)
            setSuccessful(true);
        
        let updatedFormInput = { ...formInput };
        updatedFormInput[event.target.name] = event.target.value.trim();
        setFormInput(updatedFormInput);
    }

    const handleFailure = () => {
        if (!successful) {
            return <div className="failure">Login failed.</div>
        }
    }

    const handleButtonClicked = (event) => {
        signInWithEmailAndPassword(auth, formInput.email, formInput.password)
            .then((userCredential) => {
                // User has successfully signed in
                const credentials = userCredential.user;

                // Now let's get the user's profile 
                getUserByEmail(formInput.email)
                    .then((u) => {
                        if (u.length > 0) {
                            const user = u[0];
                            input.model.auth.setIsLoggedIn(true, credentials, user);
                        }
                        setSuccessful(true);
                    })
                    .catch((error) => console.log(error));
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                setSuccessful(false);

            });
    }

    return (
        <div className="signin-container">
            <h2>Sign In</h2>
            {handleFailure()}
            <div className="username-wrapper input-field">
                <div className="input-label">email<span className="required">*</span></div>
                <TextField
                    name="email"
                    onChange={handleInputChange}
                    value={formInput.email}
                    label="email"
                    variant="outlined"
                    required />
            </div>
            <div className="password-wrapper input-field">
                <div className="input-label">Password<span className="required">*</span></div>
                <TextField
                    name="password"
                    onChange={handleInputChange}
                    value={formInput.password}
                    type="password"
                    label="password"
                    variant="outlined"
                    requried />
            </div>
            <button onClick={handleButtonClicked} className="button-19" role="button">Sign in</button>
        </div>
    );
}

export default Signin;
/***************************************************************/
