import React, { useState, useRef, useEffect } from 'react';
import FollowersModal from "../FollowersModal/FollowersModal.js";
import "./NumFollowersDisplay.scss";
import inlineStyles from "./inlineStyles.js";
import helperFunctions from "./helperFunctions.js";

const NumFollowersDisplay = (input) => {

    const [modelListenerId, setModelListenerId] = useState(null);
    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
        });
        setModelListenerId(tempId);

        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);
    
    const determineNumFollowers = () => {
        return input.numFollowers === null ? 1 : input.numFollowers; 
    };

    const handleClick = () => {
        input.model.setPanel("followersModal", true);
    }

    return (
        <div onClick={handleClick} className="numfollowersdisplay-container">
            <p>{determineNumFollowers()}</p>
            <p>followers</p>
            <FollowersModal
                profilePicture={input.profilePicture}
                model={input.model}
                followers={input.followers}
            />
        </div>
    );
}

export default NumFollowersDisplay;
/***************************************************************/
