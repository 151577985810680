/**************************************************************/
class SettingsDialogModel {
    constructor(app) {
        this.numCards = null; 
        this.contentInfo = null;
        this.allowDuplicates = null; 
        this.theme = null; 
        this.contentType = null; 
        this.totalInventorySize = null; 
        this.app = app; 
        this.initialized = false; 
        this.tempSettings = {
            numCards: null, 
            contentInfo: null, 
            contentType: null, 
            theme: null, 
            allowDuplicates: null,
            totalInventorySize: null
        };
        this.pastSettings = [];
        this.listeners = [];
    }
    /***************************************************************/
    setTotalInventorySize(size) {
        this.totalInventorySize = size; 
        this.tempSettings.totalInventorySize = size; 
        this.app.notifyListeners("settingsDialog.totalInventorySize");
    }
    /***************************************************************/
    getSettings() {
        return {
            numCards: this.numCards,
            contentInfo: this.contentInfo,
            contentType: this.contentType,
            theme: this.theme,
            allowDuplicates: this.allowDuplicates,
            totalInventorySize: this.totalInventorySize
        };
    }
    /***************************************************************/
    getTempSettings() {
        return this.tempSettings;
    }
    /***************************************************************/
    generateGeneralUri(base,numCards, contentType, contentInfo, duplicates) {
        let uri = "/numCards/" + numCards + "/content/" + contentType; 
        if (contentType === "picures")
            uri += "/" + contentInfo;
        
        if (contentType === "pictures" || contentType === "alphanum")
            uri += "/duplicates/" + duplicates; 
        
        return base + uri; 
    }
    /***************************************************************/
    updateSettings() {
        this.pastSettings.push({
            allowDuplicates: this.allowDuplicates,
            numCards: this.numCards, 
            contentInfo: this.contentInfo, 
            contentType: this.contentType,
            theme: this.theme,
            totalInventorySize: this.totalInventorySize
        });

        const changed = this.allowDuplicates !== this.tempSettings.allowDuplicates || this.numCards !== this.numCards;

        this.allowDuplicates = this.tempSettings.allowDuplicates;
        this.numCards = this.tempSettings.numCards;
        this.contentInfo = this.tempSettings.contentInfo;
        this.contentType = this.tempSettings.contentType;
        this.theme = this.tempSettings.theme;

        return changed; 
    }

    /***************************************************************/
    generateUri(base) {
        return this.generateGeneralUri(base,
            this.numCards,
            this.contentType,
            this.contentInfo,
            this.allowDuplicates);
    }
    /***************************************************************/
    generateTempUri(base) {
        return this.generateGeneralUri(base,
            this.tempSettings.numCards,
            this.tempSettings.contentType,
            this.tempSettings.contentInfo,
            this.tempSettings.allowDuplicates
        );
    }
    /***************************************************************/
    init(numCards, contentType, contentInfo, allowDuplicates = false, theme = "main") {
        this.numCards = numCards; 
        this.contentType = contentType; 
        this.contentInfo = contentInfo;
        this.allowDuplicates = allowDuplicates; 
        this.theme = theme; 
        this.initialized = true; 

        this.tempSettings.numCards = numCards;
        this.tempSettings.contentInfo = contentInfo;
        this.tempSettings.contentType = contentType;
        this.tempSettings.allowDuplicates = allowDuplicates;
        this.tempSettings.theme = theme; 
    }
    /*****************************************************************/
    setTempNumCards(nc) {
        this.tempSettings["numCards"] = nc; 
        this.app.notifyListeners("settingsDialog.tempSettings.numCards");
    }
    /*****************************************************************/
    setTempAllowDuplicates(dup) {
        this.tempSettings["allowDuplicates"] = dup === "true" || dup === true; 
        this.app.notifyListeners("settingsDialog.tempSettings.allowDuplicates");
    }
    /*****************************************************************/
    setNumCards(nc) {
        this.numCards = nc; 
        this.app.notifyListeners("settingsDialog.numCards");
    }
    /*****************************************************************/
    setAllowDuplicates(duplicates) {
        this.allowDuplicates = duplicates;
        this.app.notifyListeners("settingsDialog.allowDuplicates");
    }
    /***************************************************************/
    unregisterListener(id) {
        if (id >= 0 && id < this.listeners.length) {
            this.listeners[id] = null;
        }
    }
    /***************************************************************/
    registerListener(func) {
        this.listeners.push(func);
        return this.listeners.length - 1;
    }
    /***************************************************************/
    notifyListeners(propsChanged) {
        for (let i = 0; i < this.listeners.length; i++) {
            const listener = this.listeners[i];
            if (listener !== null && listener !== undefined) {
                listener(propsChanged);
            }
        }
    }
}

export default SettingsDialogModel; 
