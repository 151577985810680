import React, { useState, useRef, useEffect } from 'react';
import LoadingStateConstants from './LoadingStateConstants.js';
import "./Loading.scss";

/***************************************************************/
const Loading = (input) => {
    /***************************************************************/
    const [appListenerId, setAppListenerId] = useState(null);
    const [open, setOpen] = useState(LoadingStateConstants.SHOW); // this is open by default until closed

    /***************************************************************/
    useEffect(() => {
        const tempId = input.appModel.registerListener((propsChanged) => {
            if (propsChanged === "panels.loading") {
                setOpen(input.appModel.panels.loading);
            }
        });
        setAppListenerId(tempId);


        return function cleanup() {
            input.appModel.unregisterListener(appListenerId);
            setAppListenerId(null);
        };

    }, []);
    /***************************************************************/
    const determineShow = () => {
        if (open === LoadingStateConstants.SHOW) return "loading";
        else if (open === LoadingStateConstants.FADE) return "loading fadeout-style";
        else if (open === LoadingStateConstants.HIDE) return "loading hide";
    }
    /***************************************************************/
    return (
        <div className={determineShow()}>
            <div className="centerdiv">
                <ul>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>
        </div>
    );
};
/***************************************************************/
export default Loading;
/***************************************************************/
