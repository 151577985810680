import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Routes, useParams } from "react-router-dom";
import AppModel from "./AppModel.js";
import unicornBg from "./unicornBg.jpg";
import whiteBg from "./white.png";
import blueBorder from "./blueBorder.jpg";
import blueDoveBg from "./blueDoveBg.jpg";
import blueDoveBg2 from "./blueDoveBg2.jpg"
import AppRoutes from "./routes/AppRoutes.js";
import './App.css';
//import "./BlueTheme.scss";
import "./mediaQueries.scss";
import "./animation.scss";

//import { Amplify, Storage } from 'aws-amplify';
//import awsconfig from "./aws-exports";
//Amplify.configure(awsconfig);

const app_model = new AppModel();
app_model.init();

const makeRoutes = () => {
  return AppRoutes.map((r, i) => {
    const Component = r.component;
    let allProps = { ...r.props };
    allProps[r.appModel ? "appModel" : "model"] = app_model;

    return <Route key={i}
      path={r.path}
      element={<Component {...allProps} />} />
  });
}

function App() {
  return (
    <Routes>{makeRoutes()}</Routes>
  );
};

export default App;