import React, { useState, useRef, useEffect } from 'react';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Uppy, { debugLogger } from '@uppy/core';
import { DragDrop } from '@uppy/react';

import { FilePond, File, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import helperFunctions from './helperFunctions.js';
import "./ImageDropZone.scss";
import inlineStyles from "./inlineStyles.js";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)

const ImageDropZone = (input) => {

    const [modelListenerId, setModelListenerId] = useState(null);
    const [files, setFiles] = useState([]);
    const [numCards, setNumCards] = useState(input.model.maker.settings.numCards);
    const [hasErrors, setHasErrors] = useState(false);

    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
            if (propsChanged === "maker.settings.numCards")
                setNumCards(input.model.maker.settings.numCards);
        });
        setModelListenerId(tempId);

        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);

    const determineMinImagesToUpload = () => {
        const numUniqueCards = (numCards / 2) + 1; 
        return numUniqueCards;
    };

    const determineNumCardsLeftToUpload = (nUploaded) => {
        const numUniqueCards = determineMinImagesToUpload();
        return (numUniqueCards - nUploaded);
    }

    const determineDisplayErrors = () => {
        if (hasErrors)
            return <p className="errors"></p>
        return null; 
    }

    const handleSetFiles = (f) => {
        const fClone = [...f];
        setFiles(fClone);
    }

    return (
        <div className="imagedropzone-container">
            <FormControl className="form-control">
                <FormLabel className="form-label">Game Name</FormLabel>
                <p><b>Required</b>: Game name can only contain letters, numbers, and underscores.</p>
                {determineDisplayErrors()}
                <TextField
                    name="game-name"
                    variant="outlined" />
            </FormControl>
            <FormControl className="form-control">
                <FormLabel className="form-label">Folder name</FormLabel>
                <p><b>Required</b>: Can only contain letters, numbers, and underscores</p>
                <p>All images in your game are stored in a folder on a server. You must provide a name for your folder.</p>
                {determineDisplayErrors()}
                <TextField
                    name='folder-name'
                    variant="outlined" />
            </FormControl>
            <FormControl className="form-control">
                <FormLabel className="form-label">Description</FormLabel>
                {determineDisplayErrors()}
                <TextField
                    name="game-description"
                    multiline
                    rows={5}
                    variant="outlined" />
            </FormControl>
            <div className="uploader-wrapper">
                <FormLabel className="form-label">Upload Images</FormLabel>
                <p>Upload <b>{determineMinImagesToUpload()} or more</b> unique images.</p>
                <div className="uploader">
                    <FilePond
                        files={files}
                        onupdatefiles={handleSetFiles}
                        allowMultiple={true}
                        maxFiles={22}
                        acceptedFileTypes={['image/jpg', 'image/jpeg', 'image/png', 'image/gif']}
                        server={null}
                        name="files"
                        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                    />
                </div>
                <p>You need to upload at least <b>{determineNumCardsLeftToUpload(files.length)}</b> unique images.</p>
            </div>
        </div>
    );
}

export default ImageDropZone;
/***************************************************************/
