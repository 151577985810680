import React, { useState, useRef, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import CardFactory from './CardFactory/CardFactory.js';
import "./Card.scss";

const Card = (input) => {
    /***************************************************************/
    const [appListenerId, setAppListenerId] = useState(null);
    const [flipped, setFlipped] = useState(false);
    const [matchFound, setMatchFound] = useState(false);
    
    console.log("In the Card component");
    console.log("What is the content info? " + input.contentInfo);
    /***************************************************************/
    useEffect(() => {
        const tempId = input.gameModel.registerListener((propsChanged) => {
            let cardStr = "cardInventory[" + input.content.id + "]";
            let card = input.gameModel.getCard(input.content.id);
            if (propsChanged === cardStr + ".flipped") 
                setFlipped(card.flipped);
            if (propsChanged === cardStr + ".matchFound") 
                setMatchFound(card.matchFound);               
        });
        setAppListenerId(tempId);


        return function cleanup() {
            input.gameModel.unregisterListener(appListenerId);
            setAppListenerId(null);
        };

    }, []);
    /***************************************************************/
    const determineFlipped = () => {
        let classes = ["flip-card"];
        if (flipped) classes.push("flipped-card");

        return classes.join(" ");
    }
    /***************************************************************/
    const determineMatchFound = () => {
        if (matchFound)
            return "card-wrapper-matched flip-card-back";
        return "card-wrapper flip-card-back";
    }
    /***************************************************************/
    const determineTheme = () => {
        let classes = ["card-wrapper", "flip-card-front"];
        if (input.contentInfo === "unclerichard") {
            classes.push("card-back-blue")
        }
        else {
            classes.push("card-back");
        }
        return classes.join(" ");
    }


    /***************************************************************/
    const handleClick = () => {
        input.gameModel.flipCard(input.content);
    };
    /***************************************************************/

    determineTheme();

    return (
        <Box className={determineFlipped()} onClick={handleClick}>
            <Box className="flip-card-inner">
                <Paper
                    className={determineTheme()}
                    elevation={3}>
                    <Box />
                </Paper>
                <Paper
                    className={determineMatchFound()}
                    elevation={3}>
                    <CardFactory
                        content={input.content}
                        gameModel={input.gameModel}
                        contentType={input.contentType} />
                </Paper>

            </Box>
        </Box>
    );
};

/***************************************************************/
export default Card;                                          
/***************************************************************/
