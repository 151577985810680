import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faClockFour } from '@fortawesome/free-solid-svg-icons';
import "./Timer.scss";
import inlineStyles from "./inlineStyles.js";
import helperFunctions from "./helperFunctions.js";

const Timer = (input) => {

    const [modelListenerId, setModelListenerId] = useState(null);
    const [mins, setMins] = useState(input.model.timer.getPrettyTime().mins);
    const [secs, setSecs] = useState(input.model.timer.getPrettyTime().secs);

    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
            if (propsChanged === "timer.ticks") {
                setMins(input.model.timer.getPrettyTime().mins);
                setSecs(input.model.timer.getPrettyTime().secs);
            }
        });
        setModelListenerId(tempId);
       // input.model.timer.start();

        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);
    

    return (
        <div className="timer-container">
            <div className="timer">
                <FontAwesomeIcon
                    className="timer-icon"
                    icon={faClockFour} />
                <div className="timer-mins">{mins}</div>
                <div className="timer-divider">:</div>
                <div className="timer-secs">{secs}</div>
            </div>
        </div>
    );
}

export default Timer;
/***************************************************************/
