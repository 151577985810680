import GameModel from "./components/Game/GameModel.js";
import SettingsDialogModel from "./models/SettingsDialog/SettingsDialogModel.js";
import SessionModel from "./models/Session/SessionModel.js";
import GameMakerModel from "./models/GameMaker/GameMakerModel.js";
import Timer from "./models/Timer/TimerModel.js";
import Auth from "./models/Auth/AuthModel.js";
import AWSConnectorModel from "./AWSConnectorModel.js";

/**************************************************************/
class AppModel {
    constructor() {
        this.name = "oliviasgames.matching";
        this.game = new GameModel();
        this.game.setApp(this);
        this.aws = new AWSConnectorModel(this);
        this.panels = {
            loading: 2,
            won: false,
            playButton: false, 
            appBody: false,
            sideBar: false,
            settings: false,
            followersModal: false, 
            followingModal: false,
            authModal: false
        };
        this.listeners = [];
        this.auth = new Auth(this);
        this.settingsDialog = new SettingsDialogModel(this);
        this.session = new SessionModel(this);
        this.maker = new GameMakerModel(this);
        this.timer = new Timer(this);
        this.extra = null; // extra data to be injected where needed 
    }
    /**************************************************************/
    init(){
        this.auth.init();
    }

    /**************************************************************/
    setExtra(e) {
        this.extra = {}; 
        this.extra = { ...this.extra, ...e };
        this.notifyListeners("extra");
    }
    /**************************************************************/
    extraIsSet() {
        return this.extra !== null; 
    }
    /**************************************************************/
    setPanel(p, v) {
        this.panels[p] = v; 
        const prop = "panels." + p; 
        this.notifyListeners(prop);
    }
    /***************************************************************/
    setGame(game) {
        this.game = game;
    }
    /***************************************************************/
    updateSettings() {
        this.settingsDialog.updateSettings();
        this.session.sessionReset();

        this.setPanel("sideBar", false); // close the sidebar
        this.game.numCards = this.settingsDialog.numCards; 
        this.notifyListeners("settingsDialog.all");

        // start new game
        this.game.doPlayAgain();
    }
    /***************************************************************/
    unregisterListener(id) {
        if (id >= 0 && id < this.listeners.length) {
            this.listeners[id] = null;
        }
    }
    /***************************************************************/
    registerListener(func) {
        this.listeners.push(func);
        return this.listeners.length - 1;
    }
    /***************************************************************/
    notifyListeners(propsChanged,extra=null) {
        for (let i = 0; i < this.listeners.length; i++) {
            const listener = this.listeners[i];
            if (listener !== null && listener !== undefined) {
                listener(propsChanged,extra);
            }
        }
    }
}

export default AppModel; 