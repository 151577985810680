import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';
import JustProfilePicture from '../UserProfile/JustProfilePicture/JustProfilePicture';
import "./UpdateProfile.scss";
import { helperFunctions, MAX_DESCRIPTION_CHARS } from "./helperFunctions.js";

const UpdateProfile = (input) => {
    const [modelListenerId, setModelListenerId] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(input.model.auth.isLoggedIn);
    const [buttonClicked, setButtonClicked] = useState(false);
    const [userInfo, setUserInfo] = useState({
        username: input.model.auth.userProfile.data.username,
        email: input.model.auth.userProfile.data.email,
        description: input.model.auth.userProfile.data.description,
        displayName: input.model.auth.userProfile.data.displayName,
        profilePicture: input.model.auth.userProfile.data.profilePicture,
        instagram: input.model.auth.userProfile.data.instagram
    });
    const [userProfilePicture, setUserProfilePicture] = useState(input.model.auth.userProfile.data.profilePicture);
    const [userProfilePictureInfo, setUserProfilePictureInfo] = useState(input.model.auth.userProfile.data.profilePicture);
    const [showResult, setShowResult] = useState(false);
    const [hasErrors, setHasErrors] = useState(false);

    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
            if (propsChanged === "auth.isLoggedIn")
                setIsLoggedIn(input.model.auth.isLoggedIn);
            if (propsChanged === "auth.userProfile.data") {
                setUserInfo({
                    username: input.model.auth.userProfile.data.username,
                    email: input.model.auth.userProfile.data.email,
                    description: input.model.auth.userProfile.data.description,
                    displayName: input.model.auth.userProfile.data.displayName,
                    profilePicture: input.model.auth.userProfile.data.profilePicture,
                    instagram: input.model.auth.userProfile.data.instagram
                });
                setShowResult(true);
            }
        });
        setModelListenerId(tempId);


        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);
    
    const handleInputChange = (event, field) => {
        if (buttonClicked) {
            setButtonClicked(false);
            setShowResult(false);
        }
        
        if (field !== "username" && field !== "email") {
            let updatedUserInfo = { ...userInfo };
            updatedUserInfo[field] = event.target.value;
            setUserInfo(updatedUserInfo);
        }
    };

    const handleUploadFileChange = (e) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            setUserProfilePictureInfo(e.target.files[0]);
            setUserProfilePicture(reader.result)
        };

        try {
            reader.readAsDataURL(e.target.files[0]);
        } catch (error) {
            console.error(error);
        }
    };

    const handleButtonClick = (e) => {
        helperFunctions.manageErrors(userInfo).then((tempErrors) => {
            console.log("Button has been clicked");
            if (tempErrors.totalErrors === 0) {
                // We need to determine which things we want to update
                console.log("Zero errors found");
                setHasErrors(false);
                let toUpdate = { };
                let totalToUpdate = 0; 

                const compPairs = {
                    "displayName": [input.model.auth.userProfile.data.displayName, userInfo.displayName],
                    "description": [input.model.auth.userProfile.data.description, userInfo.description],
                    "instagram": [input.model.auth.userProfile.data.instagram, userInfo.instagram]
                };

                Object.keys(compPairs).forEach((propName) => {
                    const [p1, p2] = compPairs[propName];
                    if (p1 !== p2) {
                        toUpdate[propName] = userInfo[propName];
                        totalToUpdate += 1; 
                    }
                });

                if (input.model.auth.userProfile.data.profilePicture !== userProfilePictureInfo) {
                    console.log("We made it into this giant update profilePicture thing");
                    const folder = "uploadedProfileImages";
                    const params = {
                        Body: userProfilePictureInfo,
                        Bucket: input.model.aws.getBucketName(),
                        Key: folder + "/" + userProfilePictureInfo.name
                    };
                    
                    const s3 = input.model.aws.getS3();

                    s3.putObject(params, (error, data) => {
                        if (error) {
                            console.log(error);
                        }
                        else {
                            console.log("put object");
                            toUpdate["profilePicture"] = input.model.aws.getURL(folder, userProfilePictureInfo.name);
                            input.model.auth.updateUser({ username: userInfo.username, toUpdate: toUpdate });
                        }
                    });
                }
                else if (totalToUpdate > 0) {
                    input.model.auth.updateUser({username: userInfo.username, toUpdate: toUpdate});
                }
            }
            else {
                setHasErrors(true);
                setShowResult(true);
            }
        });

        e.stopPropagation();
    }
    const determineDisplayResults = () => {
        if (showResult) {
            if (hasErrors) {
                return (<div className="has-errors">
                    <h3>Whoops, errors found.</h3>
                </div>)
            }
            return (<div className='success'>
                <h3>Success!</h3>
            </div>);
        }

        return null; 
    };

    const determineUI = () => {
        if (isLoggedIn) {
            return (
                <>
                    {determineDisplayResults()}
                    <div className="edit-profile-form">
                        <div className="displayname-wrapper input-field">
                            <div className="input-label">Display Name</div>
                            <TextField
                                name="displayName"
                                value={userInfo.displayName}
                                onChange={(event) => handleInputChange(event, "displayName")}
                                label="display name"
                                variant="outlined" />
                        </div>
                        <div className="displayname-wrapper input-field">
                            <div className="input-label">Instagram</div>
                            <TextField
                                name="instagram"
                                value={userInfo.instagram}
                                onChange={(event) => handleInputChange(event, "instagram")}
                                label="instagram"
                                variant="outlined" />
                        </div>
                        <div className="profilePicture-wrapper input-field">
                            <div className="input-label">Profile Picture</div>
                            <div className="profile-picture-input-wrapper">
                                <div className="profile-picture-prev">
                                    <JustProfilePicture
                                        width="120px"
                                        height="120px"
                                        model={input.model}
                                        username={userInfo.username}
                                        profilePicture={userProfilePicture}
                                    />
                                </div>
                                <div>
                                    <Input
                                        type="file"
                                        onChange={handleUploadFileChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="email-wrapper input-field">
                            <div className="input-label">Email</div>
                            <TextField
                                name="email"
                                value={userInfo.email}
                                onChange={(event) => handleInputChange(event, "email")}
                                label="email"
                                disabled={true}
                                variant="outlined" />
                        </div>
                        <div className="username-wrapper input-field">
                            <div className="input-label">Username</div>
                            <TextField
                                name="username"
                                value={userInfo.username}
                                onChange={(event) => handleInputChange(event, "username")}
                                label="username"
                                disabled={true}
                                variant="outlined" />
                        </div>
                        <div className="description-wrapper input-field">
                            <div className="input-label">Description</div>
                            <p>Description can have up to <b>{MAX_DESCRIPTION_CHARS}</b> characters.</p>
                            <TextField
                                name="description"
                                value={userInfo.description}
                                onChange={(event) => handleInputChange(event, "description")}
                                label="description"
                                multiline={true}
                                rows={5}
                                fullWidth={true}
                                variant="outlined" />
                            <p>You have <b>{helperFunctions.numLeft(userInfo.description.length)}</b> characters left.</p>
                        </div>
                        <button 
                            onClick={handleButtonClick}
                            className="button-19"
                            style={{ marginTop: "1em" }}>Update</button>
                    </div>
                </>
            );
        }

        return null; 
    };

    return (
        <div className="updateprofile-container">
            {determineUI()}
        </div>
    );
}

export default UpdateProfile;
/***************************************************************/
