class CardModel {
    constructor(c,t="default") {
        this.flipped = false;
        this.matchFound = false;
        this.content = c.content;
        this.type = t;
        this.id = c.id;
        this.pairId = c.pairId; 
    }
    flip() {
        this.flipped = !this.flipped;
    }
    isMatch(c2) {
        console.log(this);
        console.log(c2);
        if (this.pairId === c2.pairId || this.content === c2.content) {
            this.matchFound = true;
            c2.matchFound = true;
            return true;
        }

        return false;
    }
    setMatchFound(mf) {
        this.matchFound = mf;
    }

    getFlipped() {
        return this.flipped;
    }

    getMatchFound() {
        return this.matchFound;
    }
}

export default CardModel; 