import React, { useState, useEffect } from 'react';
import GameModifyMenu from "./GameModifyMenu/GameModifyMenu.js";
import CardMedia from '@mui/material/CardMedia';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import "./GameListing.scss";

const GameListing = (input) => {
    const [isLoggedIn, setIsLoggedIn] = useState(input.model.auth.isLoggedIn);
    const [modelListenerId, setModelListenerId] = useState(null);

    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
            if (propsChanged === "auth.isLoggedIn")
                setIsLoggedIn(input.model.auth.isLoggedIn);
        });
        setModelListenerId(tempId);

        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);
    
    const makeLink = () => {
        let baseUri = "https://matching.oliviasgames.com/content/" + input.game.contentType; 
        if (input.game.contentType === "pictures")
            baseUri += "/" + input.game.folder;
        return baseUri; 
    }


    const makeCardMedia = () => {
        if (input.game.thumbnail !== undefined && input.game.thumbnail !== "") {
            return <CardMedia
                component="img"
                height="140"
                image={input.game.thumbnail}
            />
        }

        return null; 
    }

    const displayCardHeader = () => {
        if (isLoggedIn) {
            const isLoggedInUser = input.model.auth.userProfile.belongsToMe(input.game.username);
            if (isLoggedInUser && input.game.contentType === "pictures") {
                //console.log(input.game);
                return (
                    <GameModifyMenu
                        owner={input.owner}
                        model={input.model}
                        game={input.game}
                    />
                );              
            }
        }

        return null; 
    }

    return (
        <div className="gamelisting-container">
            <Card>
                {displayCardHeader()}
                <CardContent className="game-card-content">
                    {makeCardMedia()}
                    <div className="card-info">
                        <Typography
                            sx={{ fontSize: 14 }}
                            className="game-card-details"
                            color="text.secondary" gutterBottom>
                            {input.game.contentType}
                        </Typography>
                        <Typography
                            className="game-card-title"
                            variant="h5"
                            component="div">
                            <div className="game-title-wrapper">{input.game.title}</div>
                        </Typography>
                        <Typography
                            component="div"
                            variant="body2">
                            {input.game.description}
                        </Typography>
                    </div>
                </CardContent>
                <CardActions className="game-card-actions">
                    <form className="button-form-wrapper" action={makeLink()}>
                        <button
                            className="button-19" role="button">
                            <FontAwesomeIcon
                            className="game-card-play-icon"
                            icon={faPlay} /> <a href={makeLink()}>Play</a></button>
                    </form>
                </CardActions>
            </Card>
        </div> 
    );
}

export default GameListing;
/***************************************************************/
