import React, { useState, useRef, useEffect } from 'react';
import GameMaker from "../GameMaker/GameMaker.js";
import PageTemplate from "../PageTemplate/PageTemplate.js";
import PageBody from "../PageTemplate/PageBody/PageBody.js";
import "./GameMakerWrapper.scss";
import inlineStyles from "./inlineStyles.js";
import helperFunctions from "./helperFunctions.js";

const GameMakerWrapper = (input) => {

    const [modelListenerId, setModelListenerId] = useState(null);
    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
        });
        setModelListenerId(tempId);

        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);
    
    return (
        <div className="gamemakerwrapper-container">
            <PageTemplate
                title="game maker"
                model={input.model}>
                <PageBody model={input.model}>
                    <GameMaker model={input.model} />
                </PageBody>
            </PageTemplate>        
        </div>
    );
}

export default GameMakerWrapper;
/***************************************************************/
