/**************************************************************/
class SessionModel {
    constructor(app) {
        this.listeners = [];
        this.stats = {
            turns: 0,
            time: 0, 
            best: {
                time: null,
                turns: null
            }
        };
        this.app = app; 
    }
    /***************************************************************/
    determineBest(t) {
        if (this.stats.best.turns === null || this.stats.turns < this.stats.best.turns)
            this.stats.best.turns = this.stats.turns; 
        if (this.stats.best.time === null || t < this.stats.best.time)
            this.stats.best.time = t;
    }
    /***************************************************************/
    getBest() {
        return this.stats.best; 
    }
    /***************************************************************/
    resetTurns() {
        this.stats.turns = 0;
    }
    /***************************************************************/
    resetTime() {
        this.stats.time = 0; 
    }
    /***************************************************************/
    resetBest() {
        this.stats.best.turns = null;
        this.stats.best.time = null; 
    }
    /****************************************************************/
    sessionReset() {
        this.resetTurns();
        this.resetTime();
        this.resetBest();
    }
    /***************************************************************/
    incrementTurns() {
        this.stats.turns += 1; 
    }
    /****************************************************************/
    getTurns() {
        return this.stats.turns; 
    }
    /***************************************************************/
    unregisterListener(id) {
        if (id >= 0 && id < this.listeners.length) {
            this.listeners[id] = null;
        }
    }
    /***************************************************************/
    registerListener(func) {
        this.listeners.push(func);
        return this.listeners.length - 1;
    }
    /***************************************************************/
    notifyListeners(propsChanged) {
        for (let i = 0; i < this.listeners.length; i++) {
            const listener = this.listeners[i];
            if (listener !== null && listener !== undefined) {
                listener(propsChanged);
            }
        }
    }
}

export default SessionModel; 
