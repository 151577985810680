import React, { useState, useRef, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import "./RainbowAppBar.scss";
import inlineStyles from "./inlineStyles.js";
import helperFunctions from "./helperFunctions.js";

const RainbowAppBar = (input) => {

    const [modelListenerId, setModelListenerId] = useState(null);
    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
        });
        setModelListenerId(tempId);

        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);
    

    const determineTheme = () => {
        if (input.contentInfo === "unclerichard") {
            console.log("A bunch of photos from uncle richard in rainbowappbar")
            return "blue-border-bg";
        }
        return "rainbow-border-bg";
    }

    return (
        <div className="rainbowappbar-container">
            <AppBar
                className={determineTheme()}
                sx={{ position: 'relative' }}>
                <Toolbar sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end"

                }}>
                    <IconButton
                        className="app-bar-icon"
                        edge="start"
                        color="inherit"
                        onClick={input.handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default RainbowAppBar;
/***************************************************************/
