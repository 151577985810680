import SettingsDefaults from "../SettingsDialog/SettingsDefaults.js";


const helperFunctions = {
    determineNumCards: (numCards,input) => {
        if ((numCards === undefined || numCards === null) &&
            (input.numCards !== null || input.numCards !== undefined)
        )
            return input.numCards;

        const n = parseInt(numCards, 10);
        if (n % 2 === 0) return n;

        return SettingsDefaults.numCards;
    },
    determineDuplicates: (duplicates, input) => {
        if ((duplicates === undefined || duplicates === null) &&
            (input.duplicates !== null || input.duplicates !== undefined)
        )
            return input.duplicates;

        if (duplicates) return duplicates;
        return SettingsDefaults.duplicates;
    },
    determineTheme: (theme, input) => {
        if ((theme === undefined || theme === null) &&
            (input.theme !== null || input.theme !== undefined)) return input.theme;

        if (theme) return theme;
        return SettingsDefaults.theme;
    },
    determineContent: (content, input) => {
        if ((content === undefined || content === null) &&
            (input.content !== null || input.content !== undefined)
        ) return input.content;

        const validContent = ["color", "alphanum", "pictures"];
        for (let i = 0; i < validContent.length; i++) {
            const c = validContent[i];
            // if the number of cards is ever valid, return that number
            if (content === c) return c;
        }

        return SettingsDefaults.content;
    }
};

export default helperFunctions;