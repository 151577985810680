import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateRight, faBars, faPlay } from '@fortawesome/free-solid-svg-icons';
import Sidebar from "../Sidebar/Sidebar.js";
import "./Header.scss";

const Header = (input) => {
    const [appListenerId, setAppListenerId] = useState(null);
    useEffect(() => {
        const tempId = input.appModel.game.registerListener((propsChanged) => {
        });
        setAppListenerId(tempId);

        return function cleanup() {
            input.appModel.game.unregisterListener(appListenerId);
            setAppListenerId(null);
        }
    }, []);
    const handleReset = () => {
        input.appModel.game.resetCurrentGame();
    };
    const handleOpenMenu = () => {
        input.appModel.setPanel("sideBar", true);
    };
    const handlePlay = () => {
        input.appModel.game.doPlayAgain();
    };

    const determineTheme = () => {
        if (input.contentInfo === "unclerichard") 
            return "blue-theme";

        return "mini-header";
    }

    determineTheme();

    return (
        <header className="game-container-header">
            <div className={determineTheme()}>.</div>
            <div className="inner-header">
                <div
                    className="open-menu"
                    onClick={handleOpenMenu}>
                    <FontAwesomeIcon icon={faBars} />
                    <Sidebar
                        contentInfo={input.contentInfo}
                        model={input.appModel} />
                </div>
                <div className="game-name">
                    <a href="https://oliviasgames.com">
                    <h1 className="logo">Olivia's Games</h1>
                        <h3>matching game</h3>
                    </a>
                </div>
                <div className="col">
                    <div
                        className="reset-button"
                        onClick={handlePlay}>
                        <FontAwesomeIcon icon={faPlay} />
                    </div>
                    <div
                        className="reset-button"
                        onClick={handleReset}>
                        <FontAwesomeIcon icon={faRotateRight} />
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
/***************************************************************/