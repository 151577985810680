import React, { useState, useRef, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Slider from '@mui/material/Slider';
import "./NumCardsSlider.scss";

const NumCardsSlider = (input) => {
    const initValue = input.isMaker ? input.model.maker.settings.numCards :
        input.model.settingsDialog.numCards;
    const [modelListenerId, setModelListenerId] = useState(null);
    const [totalInventorySize, setTotalInventorySize] = useState(23);
    const [value, setValue] = useState(initValue);

    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
            if (propsChanged === "settingsDialog.numCards")
                setValue(input.model.settingsDialog.numCards);
            else if (propsChanged === "settingsDialog.tempSettings.numCards")
                setValue(input.model.settingsDialog.tempSettings.numCards);
            else if (propsChanged === "maker.settings.numCards")
                setValue(input.model.maker.settings.numCards);
            
            if (propsChanged === "settingsDialog.totalInventorySize") {
                const ts = input.model.settingsDialog.totalInventorySize; 
                if (ts >= 23)
                    setTotalInventorySize(23);
                else
                    setTotalInventorySize(ts);
            }
        });
        setModelListenerId(tempId);

        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);

    const handleChange = (event) => {
        if (!input.isMaker)
            input.model.settingsDialog.setTempNumCards(event.target.value);
        else
            input.model.maker.setSetting("numCards", event.target.value);
    };
    

    return (
        <div className="numcardsslider-container">
            <FormControl className="form-control">
                <FormLabel className="form-label">Number of Cards</FormLabel>
                <Slider
                    aria-label="Number of Cards"
                    defaultValue={value}
                    valueLabelDisplay="auto"
                    step={2}
                    marks
                    min={4}
                    max={(totalInventorySize-1)*2}
                    key={0}
                    value={value}
                    className="numcards-slider"
                    onChange={handleChange}
                    />
                <div className="slider-output">{value}</div>
            </FormControl>
        </div>
    );
}

export default NumCardsSlider;
/***************************************************************/
