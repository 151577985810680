/**************************************************************/
const ONE_SECOND = 1000; 
const NUM_SECS_IN_MIN = 60; 
class TimerModel {
    constructor(app) {
        this.listeners = [];
        this.ticks = 0; 
        this.app = app; 
        this.started = false; 
        this.timerId = null; 
    }
    /***************************************************************/
    ticksToTime(ticks = null) {
        // determine number of minutes
        const t = ticks === null? this.ticks : ticks; 
        const numMins = Math.floor(t / NUM_SECS_IN_MIN);
        const numSecs = t % NUM_SECS_IN_MIN; 
        return {
            mins: numMins,
            secs: numSecs,
            hours: null
        };
    }
    /***************************************************************/
    getPrettyTime(ticks = null) {
        const t = this.ticksToTime(ticks);
        const minsStr = t.mins < 10 ? "0" + t.mins : t.mins;
        const secsStr = t.secs < 10 ? "0" + t.secs : t.secs;

        return {
            mins: minsStr,
            secs: secsStr,
            hours: null
        };
    }
    /****************************************************************/
    start() {
        if (!this.started) {
            this.started = true; 
            this.timerId = setInterval(() => {
                this.ticks++;
                this.app.notifyListeners("timer.ticks");
            }, ONE_SECOND);
        }
    }
    /****************************************************************/
    stop() {
        clearTimeout(this.timerId);
        this.timerId = null; 
    }
    /****************************************************************/
    hasStopped() {
        return this.timerId === null; 
    }
    /***************************************************************/
    reset() {
        this.ticks = 0; 
        this.started = false; 
        this.app.notifyListeners("timer.ticks");
    }
    /****************************************************************/
    unregisterListener(id) {
        if (id >= 0 && id < this.listeners.length) {
            this.listeners[id] = null;
        }
    }
    /***************************************************************/
    registerListener(func) {
        this.listeners.push(func);
        return this.listeners.length - 1;
    }
    /***************************************************************/
    notifyListeners(propsChanged) {
        for (let i = 0; i < this.listeners.length; i++) {
            const listener = this.listeners[i];
            if (listener !== null && listener !== undefined) {
                listener(propsChanged);
            }
        }
    }
}

export default TimerModel; 
