import React, { useState, useRef, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import profilePicture from "../../../profile3.jpg";
import "./JustProfilePicture.scss";

const JustProfilePicture = (input) => {

    const [modelListenerId, setModelListenerId] = useState(null);
    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
        });
        setModelListenerId(tempId);

        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);
    
    const determineProfilePicture = () => {
        if (input.profilePicture !== "") {
            return (
                <Avatar
                    onClick={input.onClick}
                    sx={{ width: input.width, height: input.height }}
                    className="circular-avatar"
                    src={input.profilePicture}
                />
            ); 
        }

        const fontSizeStr = (parseInt(input.width,10) / 2) + "px";
        const avatarStyle = {
            width: input.width,
            height: input.height,
            fontSize: fontSizeStr
        };
        return (<Avatar
            className="circular-avatar"
            onClick={input.onClick}
            sx={avatarStyle}>
            <span className="first-letter">{input.username.charAt(0).toUpperCase()}</span>
        </Avatar>);
    }

    return (<>{determineProfilePicture()}</>);
}

export default JustProfilePicture;
/***************************************************************/
