import React, { useState, useEffect } from 'react';
import RainbowAppBar from "../RainbowAppBar/RainbowAppBar.js";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import SettingsDialog from '../SettingsDialog/SettingsDialog';
import { faWrench, faChevronCircleDown } from '@fortawesome/free-solid-svg-icons';
import inlineStyles from "./inlineStyles.js";
import Drawer from '@mui/material/Drawer';
import "./Sidebar.scss";

const Sidebar = (input) => {
    const [modelListenerId, setModelListenerId] = useState(null);
    const [open, setOpen] = useState(false);

    const handleClose = (event) => {
        input.model.setPanel("sideBar", false);
        event.stopPropagation();
    };

    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
            if (propsChanged === "panels.sideBar") {
               // console.log("we heard sidebar has been changed");
                const sidebarOpen = input.model.panels.sideBar;
                //console.log(sidebarOpen);
                setOpen(sidebarOpen);
            }
        });
        setModelListenerId(tempId);

        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);

    return (
        <div className="sidebar-container">
            <Drawer
                anchor="left"
                open={open}
                onClose={handleClose}
            >
                <RainbowAppBar
                    model={input.model}
                    contentInfo={input.contentInfo}
                    handleClose={handleClose}
                />
                <List
                    sx={{
                        backgroundColor: "rgba(223, 239, 249, 0.4)",
                        width: "100vw",
                        boxSizing: "border-box",
                        marginTop: "-0.5em !important"
                    }}
                >
                    <ListItem>
                        <Accordion
                            sx={{
                                width: "100%",
                                boxSizing: "border-box",
                            }}
                        >
                            <AccordionSummary
                                sx={{
                                    boxSizing: "border-box",
                                    width: "100%"
                                }}
                                expandIcon={<FontAwesomeIcon 
                                    style={inlineStyles.listItemIcon}
                                icon={faChevronCircleDown} />}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <FontAwesomeIcon
                                            style={inlineStyles.listItemIcon}
                                            icon={faWrench} />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <span className="list-item-text">settings</span>
                                    </ListItemText>
                                </ListItemButton>
                            </AccordionSummary>
                            <AccordionDetails
                                sx={{
                                    width: "100%",
                                    boxSizing: "border-box"
                                }}
                            >
                                <SettingsDialog 
                                    model={input.model}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </ListItem>
                </List>
            </Drawer>
        </div>
    );
}

export default Sidebar;
/***************************************************************/
