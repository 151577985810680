const inlineStyles = {
    closeButton: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        cursor: "pointer",
        backgroundColor: "rgba(223, 239, 249, 0.6)",
        paddingTop: "1em !important",
        paddingBottom: "1em !important",
        boxSizing: "border-box",
    },
    drawer: {
        boxSizing: "border-box"
    },
    close: {
        backgroundColor: "rgba(207, 232, 247,0.8)",
        padding: "1rem",
        borderRadius: "2.5rem",
        color: "rgb(1, 85, 137)",
    },
    listItemIcon: {
        color: "rgb(1, 85, 137)",
        padding: "1rem",
        borderRadius: "2.5rem",
        backgroundColor: "#DFEFF9",
        marginLeft: "-1em"
    }
};

export default inlineStyles;