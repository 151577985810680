import React, { useState, useRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import "./PictureCard.scss";

/***************************************************************/
const PictureCard = (input) => {
    
    const uriBase = "https://oliviasmem2.s3.us-west-2.amazonaws.com/";
    const uri = uriBase + input.content;

    return (
        <Box
            className="card-content"
            style={{
                backgroundColor: "#CAE4F4",
            }}
        >
            <div
                className="picture-card"
            >
                <img
                    src={`${uri}?w=200&fit=crop&auto=format`}
                    srcSet={`${uri}?w=200&fit=crop&auto=format&dpr=2 2x`}
                    style={{ objectFit: "cover" }}
                    loading="lazy"
                />
            </div>
        </Box>

    );
};

export default PictureCard;
/***************************************************************/