import React, { useState, useEffect } from 'react';
import PageHeader from "./PageHeader/PageHeader.js";
import PageFooter from "./PageFooter/PageFooter.js";
import "./PageTemplate.scss";

const PageTemplate = (input) => {
    const [isLoggedIn, setIsLoggedIn] = useState(input.model.auth.isLoggedIn);
    const [modelListenerId, setModelListenerId] = useState(null);
    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
            if (propsChanged === "auth.isLoggedIn")
                setIsLoggedIn(input.model.auth.isLoggedIn);
        });
        setModelListenerId(tempId);

        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);
    

    return (
        <div className="pagetemplate-container">
            <PageHeader
                model={input.model}
            />
            {input.children}
            <PageFooter
                model={input.model}
            />
        </div>
    );
}

export default PageTemplate;
/***************************************************************/
