import React, { useState, useRef, useEffect } from 'react';
import GameListing from "../GameListing/GameListing.js";
import "./GameList.scss";

const GameList = (input) => {
    const [modelListenerId, setModelListenerId] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(input.model.auth.isLoggedIn);

    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
            if (propsChanged === "auth.isLoggedIn")
                setIsLoggedIn(input.model.auth.isLoggedIn);
        });
        setModelListenerId(tempId);

        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);
    const makeGameList = () => {
        if (input.gameList !== null) {
            return input.gameList.map((g,i) => {
                return <GameListing key={i}
                    i={i}
                    model={input.model}
                    owner={input.owner}
                    game={g} />
            });
        }

        return null; 
    }
    return (
        <div className="gamelist-container">
            {makeGameList()}
        </div>
    );
}

export default GameList;
/***************************************************************/
