import CardContentType from "./CardContentType.js";
import AlphaNumType from "./AlphaNumCard/AlphaNumType.js";
import PictureType from "./PictureCard/PictureType.js";
import ColorType from "./ColorCard/ColorType.js";

class CardContentTypeFactory{
    constructor() {
        this.types = {
            "color": ColorType,
            "alphanum": AlphaNumType,
            "pictures": PictureType
        };
    }
    make(type) {
        if (type === "color" || type === "alphanum" || type === "pictures")
            return new this.types[type]();
        return CardContentType(type);
    }
}

const cardContentTypeFactory = new CardContentTypeFactory();
export default cardContentTypeFactory;