import React, { useState, useRef, useEffect } from 'react';
import "./AppLogo.scss";
import inlineStyles from "./inlineStyles.js";
import helperFunctions from "./helperFunctions.js";

const AppLogo = (input) => {

    const [modelListenerId, setModelListenerId] = useState(null);
    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
        });
        setModelListenerId(tempId);

        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);
    

    return (
        <div className="applogo-container">
            <div className="game-name">
                <a href="https://oliviasgames.com">
                <h1 className="logo">Olivia's Games</h1>
                    <h3>matching game</h3>
                </a>
            </div>
        </div>
    );
}

export default AppLogo;
/***************************************************************/
