import React, { useState, useEffect } from 'react';
import cryingUnicorn from "../../cryingUnicorn.jpeg";
import "./ErrorUI.scss";

const ErrorUI = (input) => {

    const [modelListenerId, setModelListenerId] = useState(null);
    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
        });
        setModelListenerId(tempId);

        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);
    

    return (
        <div className="errorui-container">
            <div className="invalid-user-wrapper">
                <div className="invalid-user">
                    <img
                        src={cryingUnicorn} /></div>
                <h1>{input.msg}</h1>
            </div>
        </div>
    );
}

export default ErrorUI;
/***************************************************************/
