import CardModel from "./CardModel.js";
import AlphaNumCardModel from "./AlphaNumCard/AlphaNumCardModel.js";
import ColorCardModel from "./ColorCard/ColorCardModel.js";
import PictureCardModel from "./PictureCard/PictureCardModel";

class CardModelFactory{
    constructor() {
        this.types = { 
            "alphanum": AlphaNumCardModel,
            "color": ColorCardModel,
            "pictures": PictureCardModel
        }
    }

    make(type, c) {
        if (type === "alphanum" || type === "color" || type === "pictures")
            return new (this.types[type])(c);   
        return new CardModel(c);
    }
};

export default CardModelFactory;