import React, { useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import "./TypeOfCard.scss";

const TypeOfCard = (input) => {

    const [modelListenerId, setModelListenerId] = useState(null);
    const [value, setValue] = useState(input.model.maker.settings.contentType);

    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
            if (propsChanged === "maker.settings.contentType")
                setValue(input.model.maker.settings.contentType);
        });
        setModelListenerId(tempId);

        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);

    const handleChange = (event) => {
        input.model.maker.setSetting("contentType", event.target.value);
    };

    return (
        <div className="typeofcard-container">
            <FormControl className="form-control">
                <FormLabel className="form-label">Type of Card</FormLabel>
                <Select
                    value={value}
                    label="typeofcard"
                    onChange={handleChange}
                >
                    <MenuItem value="pictures">Pictures</MenuItem>
                    <MenuItem value="colors">Colors</MenuItem>
                    <MenuItem value="alphanum">Alphanumeric</MenuItem>
                </Select>
            </FormControl>
        </div>
    );
}

export default TypeOfCard;
/***************************************************************/
