import React, { useState, useRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import "./ColorCard.scss";
/***************************************************************/
const ColorCard = (input) => {
    return (
                    <Box
                        className="card-content"
                        style={{
                            backgroundColor: input.content
                        }}
                    />
       
    );
};
/***************************************************************/
export default ColorCard;
/***************************************************************/