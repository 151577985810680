import React, { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateRight, faPlay } from '@fortawesome/free-solid-svg-icons';
import "./YouWon.scss";

export default function YouWon(input) {
    const [open, setOpen] = React.useState(false);
    const [appListenerId, setAppListenerId] = useState(null);

    useEffect(() => {
        const tempId = input.appModel.registerListener((propsChanged) => {
            if (propsChanged === "game.won") {
                setOpen(true);
            }
        });
        setAppListenerId(tempId);

        return function cleanup() {
            input.appModel.unregisterListener(appListenerId);
            setAppListenerId(null);
        }
    }, []);

    const handlePlayAgainNew = () => {
        input.appModel.game.doPlayAgain();
        setOpen(false);
    };

    const handlePlayAgain = () => {
        input.appModel.game.resetCurrentGame();
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const buttonStyles = {
        lineHeight: '0.7',
        letterSpacing: '0em',
        textTransform: 'none',
        color: 'black',
        borderStyle: 'solid',
        borderWidth: '15px',
        borderImage: input.contentInfo === "unclerichard" ? 'linear-gradient(90deg, #020024 0%, #090979 35%, #00d4ff 100%) 1' : 'linear-gradient(45deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82) 1',
    };

    console.log("What is the content info in YouWon?");
    console.log(input.contentInfo);

    const iconStyles = {
        marginRight: "0.4em",
        fontSize: "1rem"
    };

    const getPrettyTime = (ti) => {
        const t = input.appModel.timer.getPrettyTime(ti);
        return t.mins + ":" + t.secs; 
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div id="alert-dialog-title" className="you-won-header">
                    <h3>Winner</h3>
                    <h4>Good job</h4>
                </div>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className="you-won-content">
                        You matched all in the cards in <b>{input.appModel.session.getTurns()}</b> turns and <b>{getPrettyTime(null)}</b>!
                        <br /><br />
                        <b>Best Number of Turns: </b>{input.appModel.session.getBest().turns}<br />
                        <b>Best Time: </b>{getPrettyTime(input.appModel.session.getBest().time)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="dialog-actions-override">
                    <Button
                        onClick={handlePlayAgainNew}
                        style={buttonStyles}
                        className="you-won-buttons">
                        <FontAwesomeIcon
                            style={iconStyles}
                            icon={faPlay} />
                        Play New Game
                    </Button>
                    <Button
                        onClick={handlePlayAgain}
                        style={buttonStyles}
                        className="you-won-buttons">
                        <FontAwesomeIcon
                            style={iconStyles}
                            icon={faRotateRight} />
                        Start Current Game Over
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
