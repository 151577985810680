import React, { useState, useRef, useEffect } from 'react';
import { useParams } from "react-router-dom";
import PageTemplate from "../PageTemplate/PageTemplate.js";
import PageBody from "../PageTemplate/PageBody/PageBody.js";
import UpdateProfile from '../UpdateProfile/UpdateProfile.js';
import ErrorUI from "../ErrorUI/ErrorUI.js";
import "./UpdateProfilePage.scss";

const UpdateProfilePage = (input) => {
    const { user } = useParams();
    const [modelListenerId, setModelListenerId] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(input.model.auth.isLoggedIn);

    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
            if (propsChanged === "auth.isLoggedIn")
                setIsLoggedIn(input.model.auth.isLoggedIn);
        });
        setModelListenerId(tempId);

        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);

    const determineUI = () => {
        if (isLoggedIn && user === input.model.auth.userProfile.data.username) 
            return <UpdateProfile model={input.model}/>


        return (<ErrorUI
            model={input.model}
            msg="Whoops! Cannot edit another user's account." />);
    }
    
    return (
        <div className="updateprofilepage-container">
            <PageTemplate
            title="update user profile"
            model={input.model}>
            <PageBody model={input.model}>
                <h1>Update Profile for {user}</h1>
                {determineUI()}
            </PageBody>
        </PageTemplate>
        </div>
    );
}

export default UpdateProfilePage;
/***************************************************************/
