import CardContentType from "../CardContentType.js";

class PictureType extends CardContentType{
    constructor() {
        super("pictures");
    }
    makeDuplicatesAllowed(pairId, id, inventory) {
        let index = (~~(Math.random() * inventory.length));
        const data = inventory[index].Key;
        let content = {};
        let dup = {};

        content = {
            "content": data,
            "id": id,
            "pairId": pairId
        };
        id++;
        dup = {
            "content": data,
            "id": id,
            "pairId": pairId
        };

        return {
            content: content,
            dup: dup,
            id: id
        };
    }
    makeNoDuplicates(pairId, id, inventory, alg = 2) {
        if (alg === 2) {
            if (pairId === 0)
                inventory = this.utils.shuffle(inventory);
        }

        let index = alg === 1 ? (~~(Math.random() * inventory.length)) : pairId;
        const data = inventory[index].Key;
        console.log("In make no duplicates: " + data);
        let content = {};
        let dup = {};

        content = {
            "content": data,
            "id": id,
            "pairId": pairId
        };
        id++;
        dup = {
            "content": data,
            "id": id,
            "pairId": pairId
        };

        if (alg === 1)
            inventory.splice(index, 1);

        return {
            content: content,
            dup: dup,
            id: id,
            inventory: inventory
        };           
    }
    make(gen, pairId, id, allowDuplicates = false, extra = null) {
        if (allowDuplicates)
            return this.makeDuplicatesAllowed(pairId, id, gen.inventory);
        return this.makeNoDuplicates(pairId, id, gen.tempInventory);
    }
};

export default PictureType; 