import React, { useState, useRef, useEffect } from 'react';
import "./FollowingModal.scss";
import UserListModal from "../UserListModal/UserListModal.js";
import inlineStyles from "./inlineStyles.js";
import helperFunctions from "./helperFunctions.js";

const FollowingModal = (input) => {

    const [modelListenerId, setModelListenerId] = useState(null);
    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
        });
        setModelListenerId(tempId);

        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);
    

    return (
        <UserListModal
                profilePicture={input.profilePicture}
                model={input.model}
                userList={input.following}
                userListName={"Following"}
                panelName={"followingModal"}
            />
    );
}

export default FollowingModal;
/***************************************************************/
