import React, { useState, useRef, useEffect } from 'react';
import Header from "../Header/Header.js";
import Game from "../Game/Game.js";
import "./AppBody.scss";

/***************************************************************/
const AppBody = (input) => {
    const [appListenerId, setAppListenerId] = useState(null);
    const [open, setOpen] = useState(true); // this is open by default until closed

    /*****************************************************************/
    // if the settings have not been initialized, initalize them because
    // we know them by now.
    /****************************************************************/
    const allowDups = input.duplicates === "true" || input.duplicates === true; 
    if (!input.appModel.settingsDialog.initialized)
        input.appModel.settingsDialog.init(input.numCards,
        input.contentType,
        input.contentInfo,
        allowDups,
        input.theme
        );
    /****************************************************************/


    /***************************************************************/
    useEffect(() => {
        // initialize the settingsDialog
        const tempId = input.appModel.registerListener((propsChanged) => {
            if (propsChanged === "panels.appBody") {
                setOpen(input.appModel.panels.appBody);
            }
        });
        setAppListenerId(tempId);


        return function cleanup() {
            input.appModel.unregisterListener(appListenerId);
            setAppListenerId(null);
        };

    }, []);
    /***************************************************************/
    const determineShow = () => {
        if (open) return "app-body";
        return "app-body hide";
    }
    /***************************************************************/

    return (
        <div className={determineShow()}>
            <Header
                appModel={input.appModel}
                contentInfo={input.contentInfo}
                contentType={input.contentType}
            />
            <Game
                appModel={input.appModel}
                contentType={input.contentType}
                contentInfo={input.contentInfo}
                duplicates={allowDups}
                settingsChangedCount={input.settingsChangedCount}
                prevSettingsChangedCount={input.prevSettingsChangedCount}
                theme={input.theme}
                numCards={input.numCards} />
        </div>
    );
};
/***************************************************************/
export default AppBody;