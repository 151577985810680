import React, { useState, useRef, useEffect } from 'react';
import TypeOfCard from "../TypeOfCard/TypeOfCard.js";
import AllowDuplicatesController from "../AllowDuplicatesController/AllowDuplicatesController.js";
import NumCardsSlider from '../NumCardsSlider/NumCardsSlider.js';
import ImageDropsZone from "../ImageDropZone/ImageDropZone.js";
import "./GameMaker.scss";

const GameMaker = (input) => {
    const [modelListenerId, setModelListenerId] = useState(null);
    const [contentType, setContentType] = useState(input.model.maker.settings.contentType);
    const [isLoggedIn, setIsLoggedIn] = useState(input.model.auth.isLoggedIn);
    
    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
            if (propsChanged === "maker.settings.contentType")
                setContentType(input.model.maker.settings.contentType);
            if (propsChanged === "auth.isLoggedIn")
                setIsLoggedIn(input.model.auth.isLoggedIn);
        });
        setModelListenerId(tempId);

        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);

    const determineShowAllowDuplicatesController = () => {
        if (contentType === "pictures" || contentType === "alphanum")
            return <AllowDuplicatesController
                isMaker={true}
                model={input.model} />
        return null; 
    }

    const determineShowImageDropZone = () => {
        if (contentType === "pictures")
            return <ImageDropsZone
                isMaker={true}
                model={input.model} />
        return null;        
    }

    return (
        <div className="gamemaker-container">
            <h2>Matching Game Maker</h2>
            <p>Make your own shareable matching game.</p>
            <div className="maker">
                <TypeOfCard 
                    isMaker={true}
                    model={input.model}
                />
                {determineShowAllowDuplicatesController()}
                <NumCardsSlider
                    isMaker={true}
                    model={input.model} />
                {determineShowImageDropZone()}
                <form className="button-form-wrapper">
                    <button
                        className="button-19" role="button">Make Game</button>
                </form>
            </div>
        </div>
    );
}

export default GameMaker;
/***************************************************************/
