import { getUser, getUserByEmail } from "../../../services/firebase/firebase.js";

const helperFunctions = {
    manageSignUpErrors: async (formInput) => {
        const requiredList = [{ field: "firstName", msg: "First name is" },
        { field: "username", msg: "Username is" },
        { field: "email", msg: "Email is" },
        { field: "password", msg: "Password is" },
        { field: "repeatPassword", msg: "Repeat password is" }];
        
        let tempErrors = {
            "required": [],
            "passwordsMustMatch": null,
            "invalidEmail": null,
            "invalidPassword": null,
            "userExists": null,
            "emailExists": null, 
            "invalidUsernameFormat": null, 
            totalErrors: 0
        };
        requiredList.forEach((d) => {
            if (formInput[d.field] === "") {
                tempErrors.required.push(d.msg + " required.");
                tempErrors.totalErrors += 1; 
            }
        });

        if (formInput.password !== formInput.repeatPassword) {
            tempErrors.passwordsMustMatch = "Passwords must match.";
            tempErrors.totalErrors += 1;
        }

        const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        const isValidEmail = formInput.email.match(emailRegex);
        if (!isValidEmail) {
            tempErrors.invalidEmail = "Please enter a valid email address.";
            tempErrors.totalErrors += 1;
        }
        else {
            const emailExists = await getUserByEmail(formInput.email);
            if (emailExists.length > 0) {
                tempErrors.emailExists = "Account exists with the email entered. Please try a different one.";
                tempErrors.totalErrors += 1;
            }  
        }
           

        const containsDigitRegex = /[0-9]/gm;
        const containsUppercaseLetter = /[A-Z]/gm; 
        const doesNotContainDigit = !(formInput.password.match(containsDigitRegex));
        const doesNotContainUppercaseLetter = !(formInput.password.match(containsUppercaseLetter));
        const tooShort = formInput.password.length < 7; 

        const isInvalidPassword = doesNotContainDigit || doesNotContainUppercaseLetter || tooShort; 
        if (isInvalidPassword) {
            tempErrors.invalidPassword = "Password does not meet the requirements.";
            tempErrors.totalErrors += 1;
        }

        const usernameRegex = /^[A-Za-z0-9]([A-Za-z\._]*)[A-Za-z0-9]$/gm;
        const isValidUsernameFormat = formInput.username.match(usernameRegex);
        if (!isValidUsernameFormat) {
            tempErrors.invalidUsernameFormat = "Username does not meet requirements.";
            tempErrors.totalErrors += 1; 
        }
        else {
            const u = await getUser(formInput.username);

            if (u.length > 0) {
                tempErrors.userExists = "Username is taken. Please try a different one.";
                tempErrors.totalErrors += 1;
            }            
        }

        return tempErrors; 
    }
};

export default helperFunctions; 
