import { getUserProfileRef, updateDoc } from "../../services/firebase/firebase.js";

/**************************************************************/
class UserProfileModel {
    constructor(app) {
        this.app = app; 
        this.profileKeys = new Set(["description",
            "displayName",
            "email",
            "profilePicture",
            "followers",
            "following",
            "instagram",
            "userid",
            "username"
        ]);
        this.data = null; 
        this.listeners = [];
    }
    /****************************************************************/
    allKeysValid(k) {
        for (let i = 0; i < k.length; i++){
            if (!this.profileKeys.has(k[i]))
                return false; 
        }

        return true; 
    }
    /****************************************************************/
    updateUser(input, creds) {
        
        if (this.userIsSet() && this.allKeysValid(Object.keys(input.toUpdate))) {
            //console.log("updating user...");
            const userProfileRef = getUserProfileRef(input);
            updateDoc(userProfileRef, { ...input.toUpdate }).then(() => {
              
                this.setUser({ ...this.data, ...input.toUpdate });
                localStorage.removeItem(this.app.name + ".liveSession");
                localStorage.setItem(this.app.name + ".liveSession", JSON.stringify({
                    user: this.data,
                    creds: creds
                }));

            });
        }
    }
    /****************************************************************/
    userIsSet() {
        return this.data !== null; 
    }
    /***************************************************************/
    belongsToMe(username) {
        return username === this.data.username; 
    }
    /***************************************************************/
    setUser(user) {
        this.data = user; 
        this.app.notifyListeners("auth.userProfile.data");
    }
    /***************************************************************/
    unsetUser() {
        this.data = null; 
        this.app.notifyListeners("auth.userProfile.data,logout");
    }
    /***************************************************************/
    unregisterListener(id) {
        if (id >= 0 && id < this.listeners.length) {
            this.listeners[id] = null;
        }
    }
    /***************************************************************/
    registerListener(func) {
        this.listeners.push(func);
        return this.listeners.length - 1;
    }
    /***************************************************************/
    notifyListeners(propsChanged) {
        for (let i = 0; i < this.listeners.length; i++) {
            const listener = this.listeners[i];
            if (listener !== null && listener !== undefined) {
                listener(propsChanged);
            }
        }
    }
}

export default UserProfileModel; 
