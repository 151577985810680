// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import UserProfile from "./UserProfile.js";
import MatchingGame from "./MatchingGame.js";

import {
    getFirestore,
    collection, 
    query, 
    doc,
    updateDoc,
    setDoc,
    addDoc,
    serverTimestamp,
    limit, 
    onSnapshot,
    where,
    getDocs
} from "firebase/firestore";
import {
    getAuth,
    onAuthStateChanged,
    signInAnonymously,
    signOut,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendEmailVerification
} from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDtFKkxLAHxjdOlje52ZzUVim7tYf0RpGM",
    authDomain: "matchinggame-d3feb.firebaseapp.com",
    projectId: "matchinggame-d3feb",
    storageBucket: "matchinggame-d3feb.appspot.com",
    messagingSenderId: "42307593719",
    appId: "1:42307593719:web:eaa3d517015ac521dafaf2",
    measurementId: "G-92KF83XEXT"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app)
const auth = getAuth(app);
const userProfileCol = collection(db, 'UserProfile');
const matchingGamesCol = collection(db, 'MatchingGame');


export const authenticateAnonymously = () => {
    signInAnonymously(auth)
        .then(() => {
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
        });
};

/*export const onAuthStateChanged(auth, (user) => {
    if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        // ...
    } else {
        // User is signed out
        // ...
    }
});*/


export const getUser = async (user) => {
    const userQuery = query(userProfileCol, limit(10), where("username", "==", user));
    const userSnapshot = await getDocs(userQuery);
    const userList = userSnapshot.docs.map(doc => doc.data());
    return userList;
}

export const getUserByEmail = async (email) => {
    const userQuery = query(userProfileCol, limit(10), where("email", "==", email));
    const userSnapshot = await getDocs(userQuery);
    const userList = userSnapshot.docs.map(doc => doc.data());
    return userList;   
};

export const getAssociatedUsers = async (user) => {
    // all users following current user 
    const followerQuery = query(userProfileCol, where("following", "array-contains", user));
    let userSnapshot = await getDocs(followerQuery);
    const followerList = userSnapshot.docs.map(doc => doc.data());

    const followingQuery = query(userProfileCol, where("followers", "array-contains", user));
    userSnapshot = await getDocs(followingQuery);
    const followingList = userSnapshot.docs.map(doc => doc.data());
    return { followers: followerList, following: followingList };
}

export const getGamesByUser = async (user) => {
    const gamesQuery = query(matchingGamesCol, limit(10), where("username", "==", user));
    const gamesSnapshot = await getDocs(gamesQuery);
    const gamesList = gamesSnapshot.docs.map(doc => doc.data());
    return gamesList;
}; 

export const getGamesByFolder = async (folder) => {
    const gamesQuery = query(matchingGamesCol, limit(10), where("folder", "==", folder));
    const gamesSnapshot = await getDocs(gamesQuery);
    const gamesList = gamesSnapshot.docs.map(doc => doc.data());
    return gamesList;
}; 

export const getGames = async () => {
    const gamesSnapshot = await getDocs(matchingGamesCol);
    const gamesList = gamesSnapshot.docs.map(doc => doc.data());
    return gamesList;
};

export const getUserProfileRef = (input) => {
    const userProfileRef = doc(db, "UserProfile", input.username);
    return userProfileRef;
}

// update UserProfile
// add UserProfile
export {
    app,
    db,
    updateDoc,
    auth,
    userProfileCol,
    matchingGamesCol,
//    authenticateAnonymously,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendEmailVerification,
    signOut
};