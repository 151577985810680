import utils from "../../utils.js";
class CardContentType{
    constructor(type) {
        this.type = type; 
        this.utils = utils; 
    }
    make(gen, pairId, id, inventory,extra = null) {
        let content = null; 
        let dup = null; 
        
        return {
            content: content,
            dup: dup,
            id: id
        };
    }
};

export default CardContentType; 