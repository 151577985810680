import cardContentTypeFactory from "./CardContentTypeFactory.js";
import isObject from "is-it-object";
class CardContentTypeGenerator{
    constructor(type) {
        this.inventory = null; 
        this.extra = null; 
        this.type = cardContentTypeFactory.make(type);
        this.typeString = type; 
        this.tempInventory = null; 
        if (type === "alphanum")
            this.inventory = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789".split("");
        else if (type === "color")
            this.inventory = "color";

    }
    createTempInventory() {
        if (this.typeString !== "color") {
            this.tempInventory = this.inventory.map((t) => {
                if (isObject(t))
                    return { ...t };
                return t;
            });
        }
    }
    setExtra(e) {
        this.extra = e; 
    }
    setInventory(inventory) {
        this.inventory = inventory;
    }
    getInventorySize() {
        if (this.inventoryIsSet() && this.typeString !== "color")
            return this.inventory.length;
        else if (this.typeString === "color")
            return 23;
        return 0; 
    }
    inventoryIsSet() {
        return this.inventory !== null; 
    }
    generate(pairId, id, allowDuplicates = false, extra = null) {
        const result = this.type.make(this, pairId, id, allowDuplicates, extra);
        if (!allowDuplicates) this.tempInventory = result.inventory;

        return result;
    }
}

export default CardContentTypeGenerator; 