import UserProfile from "../components/UserProfile/UserProfile.js";
import GameWrapper from "../components/GameWrapper/GameWrapper.js";
import UpdateProfilePage from "../components/UpdateProfilePage/UpdateProfilePage.js";
import UpdateGamePage from "../components/UpdateGamePage/UpdateGamePage.js";
import LogoutPage from "../components/LogoutPage/LogoutPage.js";
import GameMakerWrapper from "../components/GameMakerWrapper/GameMakerWrapper.js";

const AppRoutes = [
    {
        path: "",
        props: {
            numCards: 12,
            content: "color",
            duplicates: false,
            theme: "main"
        },
        appModel: true,
        component: GameWrapper
    },
    {
        path: "/maker",
        props: {},
        appModel: false,
        component: GameMakerWrapper
    },
    {
        path: "/logout",
        props: {},
        appModel: false,
        component: LogoutPage
    },
    {
        path: "/u/:user",
        props: {},
        appModel: false,
        component: UserProfile
    },
    {
        path: "/u/:user/edit",
        props: {},
        appModel: false,
        component: UpdateProfilePage
    },
    {
        path: "/u/:user/game/:game",
        props: {},
        appModel: false,
        component: UpdateGamePage
    },
    {
        path: "/",
        props: {
            content: "color",
            numCards: 12,
            duplicates: false,
            theme: "main"
        },
        appModel: true,
        component: GameWrapper
    },
    {
        path: "/numCards/:numCards/content/pictures/:pictures/duplicates/:duplicates/theme/:theme",
        props: {
            content: "pictures"
        },
        appModel: true,
        component: GameWrapper
    },
    {
        path: "/numCards/:numCards/content/pictures/:pictures/theme/:theme",
        props: {
            content: "pictures",
            duplicates: false
        },
        appModel: true,
        component: GameWrapper
    },
    {
        path: "/numCards/:numCards/content/pictures/:pictures/duplicates/:duplicates",
        props: {
            content: "pictures",
            theme: "main"
        },
        appModel: true,
        component: GameWrapper
    },
    {
        path: "/numCards/:numCards/content/alphanum/duplicates/:duplicates",
        props: {
            theme: "main"
        },
        appModel: true,
        component: GameWrapper
    },
    {
        path: "/numCards/:numCards/content/pictures/:pictures",
        props: {
            content: "pictures",
            theme: "main",
            duplicates: false
        },
        appModel: true,
        component: GameWrapper
    },
    {
        path: "/numCards/:numCards/content/:content",
        props: {
            theme: "main",
            duplicates: false
        },
        appModel: true,
        component: GameWrapper
    },
    {
        path: "/numCards/:numCards",
        props: {
            content: "color",
            theme: "main",
            duplicates: false
        },
        appModel: true,
        component: GameWrapper
    },
    {
        path: "/content/pictures/:pictures",
        props: {
            numCards: 12,
            content: "pictures",
            duplicates: false,
            theme: "main"
        },
        appModel: true,
        component: GameWrapper
    },
    {
        path: "/content/:content",
        props: {
            numCards: 12,
            duplicates: false,
            theme: "main"
        },
        appModel: true,
        component: GameWrapper
    },
    /* ADD MORE ROUTES HERE */
];

export default AppRoutes; 