import React, { useState, useRef, useEffect } from 'react';
import Signin from "../Signin/Signin.js";
import Signup from "../Signup/Signup.js";
import DialogContent from '@mui/material/DialogContent';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import "./NotLoggedInUser.scss";
import inlineStyles from "./inlineStyles.js";
import helperFunctions from "./helperFunctions.js";

const NotLoggedInUser = (input) => {

    const [modelListenerId, setModelListenerId] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(input.model.auth.isLoggedIn);

    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
            if (propsChanged === "auth.isLoggedIn")
                setIsLoggedIn(input.model.auth.isLoggedIn);
        });
        setModelListenerId(tempId);

        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);

    const determineAuthDisplay = () => {
        if (input.authMode === "signin")
            return <Signin
                model={input.model} />
        else if (input.authMode === "signup")
            return <Signup
                model={input.model} />
    }

    return (
        <div className="notloggedinuser-container">
                <Tabs
                    sx={{
                        backgroundColor: "rgba(141, 200, 232, 0.25)"
                    }}
                    value={input.authMode}
                    onChange={input.handleAuthModeChange}>
                    <Tab value="signin" label="Sign In" />
                    <Tab value="signup" label="Sign Up" />
                </Tabs >
                <DialogContent>
                    <div className="auth-content">
                        {determineAuthDisplay()}
                    </div>
                </DialogContent>
        </div>
    );
}

export default NotLoggedInUser;
/***************************************************************/
