import React, { useState, useRef, useEffect } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogTitle from '@mui/material/DialogTitle';
import "./GameImageList.scss";
import inlineStyles from "./inlineStyles.js";
import helperFunctions from "./helperFunctions.js";

const GameImageList = (input) => {

    const [modelListenerId, setModelListenerId] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(input.model.auth.isLoggedIn);
    const [images, setImages] = useState([]);
    const [openModals, setOpenModals] = useState([]);

    useEffect(() => {
        const tempId = input.model.registerListener((propsChanged) => {
            if (propsChanged === "auth.isLoggedIn")
                setIsLoggedIn(input.model.auth.isLoggedIn);
            
        });
        setModelListenerId(tempId);

        const params = {
            Bucket: input.model.aws.config.s3Bucket,
            Prefix: input.game + '/',
        };

        const s3 = input.model.aws.getS3();

        s3.listObjectsV2(params, (err, data) => {
            if (err) {
                console.log(err, err.stack);
            } else {
                if (data.Contents.length > 0) {
                    // filter out the folder file
                    let tempOpen = [];
                    const filtered = data.Contents.filter(d => d.Size > 0).map((value) => {
                        const uri = input.model.aws.url + value.Key;
                        tempOpen.push(false);
                        return { img: uri, title: "" };
                    });

                    setImages([...filtered]);
                    setOpenModals([...tempOpen]);
                }
            }
        });


        return function cleanup() {
            input.model.unregisterListener(modelListenerId);
            setModelListenerId(null);
        }
    }, []);

    const handleClose = (event, index) => {
        let temp = [...openModals];
        temp[index] = false;
        setOpenModals([...temp]);
        event.stopPropagation();
    }

    const handleOpen = (event, index) => {
        let temp = [...openModals];
        temp[index] = true;
        setOpenModals([...temp]);
        event.stopPropagation();       
    }
    

    return (
        <div className="gameimagelist-container">
            <div>Your game has <b>{images.length}</b> images. You need a minimum of <b>7</b> images. Click on an image to delete it
                or make it the game thumbnail.</div>
            <ImageList variant="quilted" sx={{ width: "100%", height: "100%" }} cols={3} rowHeight={140}>
                {images.map((item,i) => (
                    <ImageListItem key={item.img}>
                        <img
                            src={`${item.img}?w=140&h=140&fit=crop&auto=format`}
                            srcSet={`${item.img}?w=140&h=140&fit=crop&auto=format&dpr=2 2x`}
                            alt={item.title}
                            onClick={(event, index = i) => {
                                handleOpen(event, index);
                            }}
                            loading="lazy"
                        />
                        <Dialog
                            open={openModals[i]}
                            data-index={i}
                            onClose={(event, index = i) => {
                                handleClose(event, index);
                            }}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >

                            <DialogContent style={{ padding: "0em" }}>
                                <IconButton
                                    edge="start"
                                    style={{
                                        position: "absolute", 
                                        right: "1em",
                                        backgroundColor: "rgba(255,255,255,0.1)",
                                        borderRadius: "50%",
                                        top: "1em"
                                    }}
                                    onClick={(event, index = i) => {
                                        handleClose(event, index);
                                    }}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                                <img
                                    src={`${item.img}?w=140&h=140&fit=crop&auto=format`}
                                    srcSet={`${item.img}?w=140&h=140&fit=crop&auto=format&dpr=2 2x`}
                                    alt={item.title}
                                    style={{width: "100%"}}
                                    loading="lazy"
                                />
                            </DialogContent>
                        </Dialog>
                    </ImageListItem>
                ))}
            </ImageList>
        </div>
    );
}

export default GameImageList;
/***************************************************************/
