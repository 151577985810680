import React, { useState, useRef, useEffect } from 'react';
import Board from "../Board/Board.js";
import Timer from "../Timer/Timer.js";
import "./Game.scss";

/***************************************************************/
const Game = (input) => {
    const [appListenerId, setAppListenerId] = useState(null);

    /***************************************************************/
    useEffect(() => {
        console.log("We are in Game Component");
        const tempId = input.appModel.game.registerListener((propsChanged) => {
        });
        setAppListenerId(tempId);
        input.appModel.game.numCards = input.numCards; 
        input.appModel.game.contentType = input.contentType;

        return function cleanup() {
            input.appModel.game.unregisterListener(appListenerId);
            setAppListenerId(null);
        }
    }, []);
    /***************************************************************/
    return (<div className="game-container">
        <Board
            gameModel={input.appModel.game}
            contentInfo={input.contentInfo}
            contentType={input.contentType}
            duplicates={input.duplicates}
            theme={input.theme}
            settingsChangedCount={input.settingsChangedCount}
            prevSettingsChangedCount={input.prevSettingsChangedCount}
            numCards={input.numCards} />
        <Timer model={input.appModel}/>
    </div>
    );
}
/***************************************************************/
export default Game;
/***************************************************************/
