import CardContentType from "../CardContentType.js";
import { Random } from "random-js";
import utils from "../../../utils.js";
const random = new Random(); // uses the nativeMath engine
class ColorType extends CardContentType {
    constructor() {
        super("color");
    }
    makeDuplicatesAllowed(pairId, id) {
        let colors = ["rgb(255,255,0)",
            "rgb(255,204,0)",
            "rgb(255,153,0)",
            "rgb(255,102,0)",
            "rgb(255,51,0)",
            "rgb(204,255,0)",
            "rgb(204,204,0)",
            "rgb(204,153,0)",
            "rgb(204,102,0)",
            "rgb(204,51,0)",
            "rgb(153,255,0)",
            "rgb(153,204,0)",
            "rgb(153,153,0)",
            "rgb(153,102,0)",
            "rgb(102,51,153)",
            "rgb(51,255,255)",
            "rgb(51,153,255)",
            "rgb(153,51,102)",
            "rgb(0,0,51)",
            "rgb(204,0,102)",
            "rgb(204,153,153)",
            "rgb(51,0,51)",
            "rgb(0,0,255)"
        ];

        console.log("in the new color card generator");

        const data = colors[~~(Math.random() * colors.length)];
        let content = {};
        let dup = {};

        content = {
            "content": data,
            "id": id,
            "pairId": pairId
        };
        id++;
        dup = {
            "content": data,
            "id": id,
            "pairId": pairId
        };

        return {
            content: content,
            dup: dup,
            id: id
        };
    }
    make(gen, pairId, id, allowDuplicates = true, extra = null) {
        return this.makeDuplicatesAllowed(pairId, id);
    }
};

export default ColorType; 